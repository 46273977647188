import axios from "axios";

const useAxiosInstance = () => {
    
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.request.use(config => {
        // config.headers['Access-Control-Allow-Origin'] = 'https://www.yallabestoffers.com';
        // config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';

        const countryCode = localStorage.getItem('countryCode');

        if (countryCode && countryCode !== "pk") {
            config.headers['X-Country-Key'] = countryCode.toUpperCase();
        }
        return config;
    });

    return instance;
};

export default useAxiosInstance;