import React, { useState, useEffect } from "react";
import "./CookieConsentPopup.css";
import CookieSettingsPopup from "../CookieSettingsPopup/CookieSettingsPopup";
import PrivacyPolicyBottomSheet from "../PrivacyPolicyBottomSheet/PrivacyPolicyBottomSheet";

const CookieConsentPopup = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);
  const [isCookieSettingsVisible, setIsCookieSettingsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (cookieConsent !== "given") {
      setIsVisible(true);
    }
  }, []);

  const handleOpenManageCookies = () => {
    setIsVisible(false);
    setIsCookieSettingsVisible(true);
  };

  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "given");
    setIsVisible(false);
    onAccept?.();
  };

  const handleCloseManageCookies = () => {
    setIsCookieSettingsVisible(false);
    setIsVisible(true);
    onAccept?.();
  };

  const handleOpenPrivacyPolicy = () => {
    setIsVisible(false);
    setIsPrivacyPolicyVisible(true);
  };

  const handleClosePrivacyPolicy = () => {
    setIsPrivacyPolicyVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="cookie-consent-overlay">
          <div className="cookie-consent-container">
            <p className="cookie-consent-text pl-5 pr-5 pt-4">
              We use cookies to make the website function properly, improve
              performance, and serve targeted advertisements. Note that if you
              do not accept optional cookies, your experience may be disrupted.
              By continuing to use this site, you consent to the use of cookies
              as specified in your{" "}
              <span
                className="privacy-policy-link"
                onClick={handleOpenManageCookies}
              >
                Manage Cookies
              </span>
              . You can update your cookie preferences at any time. For more
              information please see our{" "}
              {/* <Link to="/privacy-policy" onClick={handleOpenPrivacyPolicy} >privacy policy </Link> and{" "} */}
              <span
                onClick={handleOpenPrivacyPolicy}
                className="privacy-policy-link"
              >
                Privacy Policy
              </span>
              .
            </p>
            <div className="cookie-consent-buttons">
              <button
                className="cookie-button-settings"
                onClick={handleOpenManageCookies}
              >
                Manage Cookies
              </button>
              <button
                className="cookie-button-accept"
                onClick={handleAcceptCookies}
              >
                Accept All Cookies
              </button>
            </div>
          </div>
        </div>
      )}
      {isCookieSettingsVisible && (
        <CookieSettingsPopup
          onConfirm={handleCloseManageCookies}
          onCancel={() => {
            setIsCookieSettingsVisible(false);
            setIsVisible(true);
          }}
        />
      )}
      {isPrivacyPolicyVisible && (
        <PrivacyPolicyBottomSheet
          open={isPrivacyPolicyVisible}
          setOpen={setIsPrivacyPolicyVisible}
          onClose={() => {
            setIsVisible(true);
          }}
          onAgree={() => {
            setIsVisible(true);
          }}
        />
      )}
    </>
  );
};

export default CookieConsentPopup;
