import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import "./SubFeatureReview.css";

import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";

const SubFeatureReview = React.memo(
  ({
    selectedSubFeatures,
    setSelectedSubFeatures,
    onNext,
    onPrevious,
    isSubmitting,
    setIsSubmitting,

    setsubFeatureReviewFeaturesData,
  }) => {
    const [error, setError] = useState(null);
    const [features, setFeatures] = useState(selectedSubFeatures);
    const { applicationId } = useContext(ApplicationIdContext);
    const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
      console.log("Updated Application ID in EligibilityForm:", applicationId);
    }, [applicationId]);

    useEffect(() => {
      setsubFeatureReviewFeaturesData(features);
    }, [features]);

    const getImageUrl = (imagePath) => {
      return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
    };
    // const [isSubmitting, setIsSubmitting] = useState(false);
    const handleNext = async () => {
      if (isSubmitting) return; // Prevent duplicate submissions
      setError(null);
      setIsSubmitting(true);
      const preferences = features.map((subFeature, index) => ({
        featureId: subFeature.featureId,
        priority: index + 1,
      }));

      const payload = {
        applicationId: applicationId,
        preferences: preferences,
      };

      try {
        const response = await axios.post(
          `${serverBaseUrl}/api/recommendations/public`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        onNext(response.data);
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        handleErrorResponse(error);
      } finally {
        setIsSubmitting(false); // Re-enable the button
      }
    };

    const handleErrorResponse = (error) => {
      if (error.response) {
        console.error(
          `Server responded with status ${error.response.status}`,
          error.response.data
        );
        setError(`Server error: ${error.response.status}`);
      } else if (error.request) {
        console.error(
          "The request was made but no response was received",
          error.request
        );
        setError("No response from server");
      } else {
        console.error("Error setting up the request", error.message);
        setError("Request setup error");
      }
    };

    const handleDragEnd = (result) => {
      if (!result.destination) return;
      const startIndex = result.source.index;
      const endIndex = result.destination.index;
      const newFeatures = Array.from(selectedSubFeatures);
      console.log(" drag end >>>>>>>>", startIndex, endIndex);

      const [removed] = newFeatures.splice(startIndex, 1);
      newFeatures.splice(endIndex, 0, removed);
      console.log("newFeatures>>>>>", newFeatures);
      setFeatures(newFeatures);
      setSelectedSubFeatures(newFeatures);
    };

    return (
      <div className="sub-feature-review-container">
        <div className="heading-section" style={{ flexDirection: "column" }}>
          <h1 className="form-title">
            Prioritize Your <span className="know">Selection</span>
          </h1>
          <h3 className="drag-text">
            Drag and drop the cards vertically to change the prioritization
          </h3>
          <span
            className="form-title-underline"
            style={{ margin: "0px" }}
          ></span>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="features">
            {(provided) => (
              <div
                className="sub-feature-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selectedSubFeatures?.map((feature, index) => (
                  <Draggable
                    key={feature.featureId}
                    draggableId={feature.featureId.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="sub-feature-item"
                      >
                        <span className="sub-feature-text">
                          <img
                            src={getImageUrl(feature?.image)}
                            alt={feature?.featureName}
                            className="selected-feature-image pr-"
                          />

                          <p>{feature.name}</p>
                        </span>
                        <span className=" sub-feature-order">{index + 1}</span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {error && <div className="error-message">{error}</div>}
        {/* <div className="review-section-actions">
      <button className="back-btn" onClick={onPrevious}>Back</button>
        <button className="review-section-next-btn" onClick={handleNext} disabled={isSubmitting}>Next</button>

      </div> */}
      </div>
    );
  }
);

export default SubFeatureReview;
