import React from "react";
import "./ConfirmationModal.css";
import bankIcon from "../../images/bank-icon.svg";
import greenCheck from "../../images/green-check.svg";
import closeIcon from "../../images/close-icon.svg";

const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

const getImageUrl = (imagePath) => {
    return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
  };

const ConfirmationModal = ({ open, card, features, productImages, secondaryBtnText, handleSecondaryBtn, primaryBtnText, handlePrimaryBtn, currency }) => {

    const description = card.description.split("\\n");
console.log(description)
    return (
      <>
        {open && (
          <div className="modal-overlay">
            <div className="modal-box">

            <div className="card-match">
                <span className="match-percent">{Math.round(card.score)}%</span> 
                <span>Match</span>
            </div>

            <div className="modal-header">
                <div className="modal-title">
                    <h4>Confirm details and <span>apply</span></h4>
                    <span className="title-underline"></span>
                </div>
                <img src={closeIcon} alt="Close" onClick={handleSecondaryBtn} />
            </div>

              <div className="modal-box-inner">
                
            <div className="card-area">
              <div className="card-image-wrapper">
                  <div className="card-match mobile">
                        <span className="match-percent">{Math.round(card.score)}%</span> 
                        <span>Match</span>
                  </div>
                  <div className="bank-info">
                  <img src={bankIcon} className="bank-icon" />
                  <span className="bank-name">{card.organizationName}</span>
                  </div>
                  <img
                    src={productImages[card.productId]}
                    alt={card.productName}
                    className="card-image"
                  />
               </div>

                <div className="card-highlights">
                    <h3 className="card-title">{card.productName}</h3>
                    {card.incentive > 0 ? <div className="cashback">{currency} {card.incentive} Cashback</div>: ''}
                </div>
            </div>

                <div className="card-features">
                    <h3>Features</h3>
                    <div>
                    {features.map(feature => (
                    <span className="card-feature">
                        <img
                        src={getImageUrl(feature.imageUrl)}
                        />
                        <span>{feature.featureName}</span>
                    </span>
                  ))}
                  </div>
                </div>
                {description[0].length > 0 ? (
                <div className="product-details">
                    <h3>Product Details</h3>
                    {description.map(line => (
                        <div><img className="green-check" src={greenCheck} alt="Check" /><span>{line}</span></div>
                    ))}
                </div>
                ) : ''}

                <div className="action-buttons">
                  { secondaryBtnText && <button className="select-button back-button" onClick={handleSecondaryBtn}>{secondaryBtnText}</button> }
                  <button className="select-button" onClick={handlePrimaryBtn}>{primaryBtnText}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  export default ConfirmationModal;