import React, { useState } from "react";
import "./CookieSettingsPopup.css"; // Ensure this path is correct

const CookieSettingsPopup = ({ onCancel, onConfirm }) => {
  const [cookieSettings, setCookieSettings] = useState({
    functional: true,
    performance: false,
    advertising: false,
  });

  const handleToggle = (type) => {
    setCookieSettings({ ...cookieSettings, [type]: !cookieSettings[type] });
  };

  const handleConfirm = () => {
    localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));
    localStorage.setItem("cookieConsent", "given");
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
    localStorage.removeItem("cookieConsent");
  };

  return (
    <div className="cookie-settings-popup">
      <div className="cookie-consent-container1">
        <div className="cookie-settings-header">
          <h2>Manage Cookie Settings</h2>
        </div>
        <div className="cookie-consent-text pl-5 pr-5 pt-4">
          <div className="cookie-setting">
            <div className="cookie-description">
              <h4>Functional</h4>
              <p>
              Our manage cookie settings page for the credit card application website ensures that your browsing experience remains smooth and personalized. By allowing you to customize your cookie preferences, you can control which types of cookies are stored on your device. Functional cookies are essential for the proper functioning of our website, enabling features such as secure login, account access, and form submissions. By adjusting your settings here, you can tailor your browsing experience to suit your needs while ensuring the necessary functionality of our site remains intact.
              </p>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={cookieSettings.functional}
                onChange={() => handleToggle("functional")}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-setting">
            <div className="cookie-description">
              <h4>Performance</h4>
              <p>
              Optimizing the performance of our credit card application website is crucial for providing you with a seamless and efficient browsing experience. Performance cookies help us gather insights into how visitors interact with our site, allowing us to identify and address any areas for improvement. By enabling performance cookies, you allow us to track page load times, site navigation, and user engagement metrics. This data helps us enhance website speed, usability, and overall performance, ensuring that you can easily access information and complete your credit card application process with ease.              </p>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={cookieSettings.performance}
                onChange={() => handleToggle("performance")}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-setting">
            <div className="cookie-description">
              <h4>Advertising</h4>
              <p>
              By enabling advertising cookies, you allow us and our advertising partners to collect data about your browsing behavior, preferences, and demographics. This information is used to serve relevant advertisements across our website and third-party platforms, ensuring that you receive advertisements that are more likely to be of interest to you. Additionally, advertising cookies help us measure the effectiveness of our advertising campaigns and improve targeting accuracy, ultimately enhancing your overall experience as you explore credit card options and offers on our site.              </p>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={cookieSettings.advertising}
                onChange={() => handleToggle("advertising")}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="cookie-consent-buttons">
          <button onClick={handleClose} className="cookie-button-settings">
            Cancel
          </button>
          <button onClick={handleConfirm} className="cookie-button-accept">
            Confirm My Choices
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieSettingsPopup;
