import React, { useState, useContext, useEffect } from "react";
import useAxiosInstance from '../../api/axios';
import ConfirmationModal from "./ConfirmationModal";
import SendEmail from '../../utils/emailService';
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import { useNavigate } from "react-router-dom";

import bankIcon from "../../images/bank-icon.svg";
import applyIcon from "../../images/apply-icon.svg";


const CardItem = ({selectedCard, setSelectedCard, card, index, productImages, setSnackbar, customerData, selectedSubFeatures, location, countryCode}) => {

    const [currency, setCurrency] = useState("AED");
    const [modalOpen, setModalOpen] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);

    const { applicationId } = useContext(ApplicationIdContext);
    const navigate = useNavigate();

    const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (countryCode === "sa") {
          setCurrency("SAR");
        } else if (countryCode == "pk") {
          setCurrency("PKR");
        } else {
          setCurrency("AED");
        }
    }, [currency]);

    const getImageUrl = (imagePath) => {
        return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
    };
    
    const handleSelect = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSubmitApplication = async () => {
        // Check if a card is selected
    
        if (!selectedCard) {
          setSnackbar({
            open: true,
            title: "Card Selection",
            message: "Please select a card first to apply.",
            severity: "error",
          });
          return;
        }
    
        // setModalOpen(true);
        // setTimeout(() => {
        //   window.open(selectedCard.routingUrl, "_blank");
        //   setModalOpen(false);
        // }, 2000);
    
        const payload = {
          applicationId: applicationId,
          customer: {
            customerId: customerData?.customerId,
          },
          location: location,
          product: {
            productId: selectedCard.productId,
          },
          applicationType: "Card",
          clientRiskProfile: customerData?.clientRiskProfile,
          status: "Submitted",
        };
    
        try {
          const response = await axios.put("/api/applications/public", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data) {
            console.log("Application submitted:", response.data);
          }
          
    
          // If the API call is successful, and you have a routing URL, redirect the user
          if (response.data?.product?.routingUrl) {
            // window.open(response.data.product.routingUrl, "_blank");
            setModalOpen(false);
            setSubmitModalOpen(true);
            setTimeout(() => {
              window.open(selectedCard.routingUrl, "_blank");
              setSubmitModalOpen(false);
              navigate("/congratulations");
            }, 2000);
          }
          await SendEmail(
            customerData,
            applicationId,
            selectedCard.productName,
            selectedSubFeatures,
          );
        } catch (error) {
          console.error("Error submitting application:", error);
          // Handle the error, show a message to the user if needed
        } finally {
          // Hide the loading indicator
          setSubmitModalOpen(false);
        }
      };

    const axios = useAxiosInstance();

    return (
        <div>
        <div
          id={`card-${index}`}
          className={`card-item ${
            selectedCard && selectedCard?.productId === card?.productId
              ? "selected"
              : ""
          }`}
          key={card.productId}
          onClick={() => handleSelect(card)}
        >
         
          {/* <div className="card-content"> */}
            <div className="card-image-wrapper">
              <div className="bank-info">
              <img src={bankIcon} className="bank-icon" />
              <span className="bank-name">{card.organizationName}</span>
              </div>
              <img
                src={productImages[card.productId]}
                alt={card.productName}
                className="card-image"
              />
            </div>

            <div className="card-info-mobile">
              <div className="card-highlights">
                  <h3 className="card-title">{card.productName}</h3>
                  {card.incentive > 0 ? <div className="cashback">{currency} {card.incentive} Cashback</div>: ''}
                  {card.instantIssuance ? <div className="instance-issuance">Instant Issuance</div>: ''}
              </div>
            </div>

            <div className="card-info">

              <div className="card-highlights">
                <h3 className="card-title">{card.productName}</h3>
                {card.incentive > 0 ? <div className="cashback">{currency} {card.incentive} Cashback</div>: ''}
                {card.instantIssuance ? <div className="instance-issuance">Instant Issuance</div>: ''}
              </div>

              <div className="card-features">
              {card.features.map(feature => (
                <span className="card-feature">
                <img
                  src={getImageUrl(feature.imageUrl)}
                />
                <span>{feature.featureName}</span>
                </span>

              ))
              }
              </div>

              {/* <div className="card-details">
              
                <span className="card-detail">
                  Annual Fee:
                  <span className="card-details-value">
                    {currency} {card.annualFee || 0}
                  </span>
                </span>
                <span className="card-detail">
                  Min Salary:
                  <span className="card-details-value">
                    {currency} {card.minimumIncome}
                  </span>
                </span>
                <span className="card-detail">
                  Welcome Bonus:
                  <span className="card-details-value">
                    {card.welcomeBonus ? card.welcomeBonus : 'N/A'}
                  </span>
                </span>

              </div> */}
              
              {/* <ViewMoreButton description={card.description} /> */}
              {/* <div className="card-actions">
                
                <button
                  className={`select-button ${
                    selectedCard &&
                    selectedCard.productId === card.productId
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleSelect(card)}
                >
                    Apply
                </button>
              </div> */}

            </div>

            <div className="card-match">
                <span className="match-percent">{Math.round(card.score)}%</span> 
                <span>Match</span>
            </div>

            <div className="card-actions">
                <img className="apply-btn" src={applyIcon} alt="Apply" onClick={() => handleSelect(card)} />
            </div>

            </div>

            <ConfirmationModal
              productImages={productImages}
              open={modalOpen}
              card={card}
              features={card.features}
              secondaryBtnText={"Choose another option"}
              handleSecondaryBtn={handleCloseModal}
              primaryBtnText={"Apply now"}
              handlePrimaryBtn={handleSubmitApplication}
              currency={currency}
            />
          {/* </div> */}
        </div>
    );
};

export default CardItem;