import React from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const Modal = ({open}) => {
    // console.log('App rendered');
    return (
      <>
        {open && (
          <div className="modal-overlay">
            <div className="modal-box">
              <Typography
                variant="h6"
                component="h2"
                style={{ marginTop: "20px", fontWeight: "bold" }}
              >
                Please wait while you are being redirected to a partner website
                for Credit Card where you can apply.
              </Typography>
              <CircularProgress />
              <Typography style={{ marginTop: "10px" }}>
                Please do not close or leave this page.
              </Typography>
            </div>
          </div>
        )}
      </>
    );
  };

  export default Modal;