import React, { useState, useEffect, useContext } from "react";
import useAxiosInstance from '../../api/axios';
import { debounce } from "lodash"; // Import debounce from lodash for optimizing API calls
import { ReactSVG } from "react-svg";
import "./FeaturesAccordion.css";
import loadingGif from "../../images/Spinner-5.gif";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import openIcon from "../../images/open.png";
import closeIcon from "../../images/close.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const FeaturesAccordion = ({
  onNext,
  onPrevious,
  screeningResponse,
  selectedSubFeatures,
  setSelectedSubFeatures,
  selectedSubFeaturesForHeader,
  setSelectedSubFeaturesForHeader,
  features,
  setFeatures,
  snackbar,
  setSnackbar,
  loading,
  selectedCount,
  setSelectedCount,
  priorityList,
  setPriorityList,
  recommendationCount,
  setRecommendationCount,
  recommendationsMessage,
  setRecommendationsMessage,
  setLoading,
}) => {

  const axios = useAxiosInstance();
  
  const [errorMessage, setErrorMessage] = useState("");

  // const [recommendationsMessage, setRecommendationsMessage] = useState("");
  // const [recommendationCount, setRecommendationCount] = useState(null); // Initialize to null
  // const [priorityList, setPriorityList] = useState([]);

  const { applicationId } = useContext(ApplicationIdContext);

  const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get("/api/productFeatures")
  //     .then((response) => {
  //       console.log(response);
  //       const featuresWithSelection = response.data.map((feature) => ({
  //         ...feature,
  //         subFeatures: feature.subFeatures
  //           ? feature.subFeatures.map((subFeature) => ({
  //               ...subFeature,
  //               selected: selectedSubFeatures.some(
  //                 (sf) => sf.featureId === subFeature.featureId
  //               ),
  //             }))
  //           : [],
  //         selected: selectedSubFeatures.some(
  //           (sf) => sf.featureId === feature.featureId
  //         ),
  //       }));
  //       setFeatures(featuresWithSelection);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching features:", error);
  //       setSnackbar({
  //         open: true,
  //         title: "Error",
  //         message: "Failed to load features.",
  //         severity: "error",
  //       });
  //     })
  //     .finally(() => setLoading(false));
  // }, [selectedSubFeatures]);

  const handleSelectSubFeature = (featureIndex, subFeatureIndex) => {
    let newFeatures = [...features];
    let feature = newFeatures[featureIndex];
    let subFeature = feature.subFeatures[subFeatureIndex];
    subFeature.selected = !subFeature.selected;

    // Update header selections
    let headerUpdates = { ...selectedSubFeaturesForHeader };
    if (subFeature.selected) {
      let headers = headerUpdates[feature.featureName] || [];
      headerUpdates[feature.featureName] = [...headers, subFeature.featureName];
    } else {
      let filteredHeaders = headerUpdates[feature.featureName].filter(
        (name) => name !== subFeature.featureName
      );
      if (filteredHeaders.length > 0) {
        headerUpdates[feature.featureName] = filteredHeaders;
      } else {
        delete headerUpdates[feature.featureName];
      }
    }

    setSelectedSubFeaturesForHeader(headerUpdates);
    setFeatures(newFeatures);
  };

  //   const fetchFeatures = async () => {
  //     try {
  //       const response = await axios.get("/api/productFeatures");
  //       const featuresWithSelection = response.data.map(feature => ({
  //         ...feature,
  //         subFeatures: feature.subFeatures.map(subFeature => ({
  //           ...subFeature,
  //           selected: selectedSubFeatures.some(sf => sf.featureId === subFeature.featureId)
  //         })),
  //         selected: selectedSubFeatures.some(sf => sf.featureId === feature.featureId)
  //       }));
  //       setFeatures(featuresWithSelection);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching features:", error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchFeatures();
  // }, [selectedSubFeatures]);
  // const handleFeatureSelection = (featureId) => {
  //   const newHeaderValues = {...selectedSubFeaturesForHeader, [featureId]: true}; // Example logic
  //   setSelectedSubFeaturesForHeader(newHeaderValues);
  // };

  const updateSelectedSubFeatures = (updatedFeatures) => {
    const selectedFeatures = updatedFeatures.flatMap((feature) =>
      feature.subFeatures.filter((subFeature) => subFeature.selected)
    );
    setSelectedSubFeatures(selectedFeatures);
  };

  // useEffect for updating message when recommendationCount changes
  useEffect(() => {
    console.log("recommendationCount>>", recommendationCount);
    if (recommendationCount !== undefined) {
      setRecommendationsMessage(recommendationCount);
    } else {
      setRecommendationsMessage("No recommendations count available");
    }
  }, [recommendationCount]); // Only re-run the effect if recommendationCount changes

  useEffect(() => {
    const count = features
      .flatMap((f) => (f.subFeatures?.length > 0 ? f.subFeatures : [f]))
      .filter((f) => f.selected)?.length;
    setSelectedCount(count);
    // Reset the error message whenever features are updated
    if (count === 0) {
      setErrorMessage("Please select at least one feature to proceed.");
    } else if (count > 5) {
      setErrorMessage("You can only select up to 5 features.");
    } else {
      setErrorMessage("");
    }
  }, [features]);
  const getImageUrl = (imagePath) => {
    return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
  };
  const toggleFeature = (index) => {
    setFeatures(
      features.map((feature, i) => ({
        ...feature,
        isOpen: i === index ? !feature.isOpen : feature.isOpen,
      }))
    );
  };

  const selectSubFeature = (subFeatureName, featureIndex, subFeatureIndex) => {
    let newSelectedCount = selectedCount;
    const newFeatures = features.map((feature, i) => {
      if (i === featureIndex) {
        const updatedSubFeatures = feature.subFeatures.map((subFeature, j) => {
          const isSelected =
            j === subFeatureIndex ? !subFeature.selected : subFeature.selected;

          if (isSelected && !subFeature.selected) newSelectedCount++;
          else if (!isSelected && subFeature.selected) newSelectedCount--;

          return {
            ...subFeature,
            selected: isSelected,
          };
        });

        if (newSelectedCount <= 5) {
          updateButtonCount(subFeatureName);
          // Update headers for UI display
          setSelectedSubFeaturesForHeader((prev) => ({
            ...prev,
            [feature.featureName]: updatedSubFeatures
              .filter((sf) => sf.selected)
              .map((sf) => sf.featureName),
          }));
        }

        return {
          ...feature,
          subFeatures: updatedSubFeatures,
        };
      }
      return feature;
    });

    if (newSelectedCount <= 5) {
      setFeatures(newFeatures);
      setSelectedCount(newSelectedCount); // Update the count state only if selection is valid

      // Call fetchRecommendations debounced to optimize API calls
      debouncedFetchRecommendations(
        newFeatures.flatMap((f) => f.subFeatures).filter((sf) => sf.selected)
      );
    } else {
      setSnackbar({
        open: true,
        title: "Feature Selection",
        message: "You can only select up to 5 features.",
        severity: "error",
      });
    }
  };

  // Debounce function setup
  const debouncedFetchRecommendations = debounce((selectedFeatures) => {
    fetchRecommendations(selectedFeatures);
  }, 300); // Debounce time can be adjusted based on user interaction speed
  // Define the fetchRecommendations function
  const fetchRecommendations = async (selectedFeatures) => {
    if (selectedFeatures.length >= 0 && selectedFeatures.length <= 5) {
      const payload = {
        applicationId: applicationId,
        // income: 65000, // Or any other dynamic data you might be using
        preferences: selectedFeatures.map((sf) => ({
          featureId: sf.featureId,
          featureName: sf.featureName,
        })),
      };
      try {
        const response = await axios.post(
          "/api/recommendations/public/count",
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        const count = response.data; // Assuming response.data is the count
        setRecommendationCount(count); // Set the state with the count
        console.log("Card Count: " + count);

        // Check if the count is greater than 0 to update the message state
        if (count > 0) {
          setRecommendationsMessage(`${count}`);
        } else {
          setRecommendationsMessage(0);
        }
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        setRecommendationsMessage(""); // In case of error, set message to empty string or some error message
      }
    }
  };

  const updateButtonCount = (subFeatureName) => {
    if (!priorityList.includes(subFeatureName)) {
      const newPriorityList = [...priorityList, subFeatureName];
      setPriorityList(newPriorityList);
    } else {
      const newPriorityList = priorityList.filter(
        (existingFeature) => existingFeature !== subFeatureName
      );
      setPriorityList(newPriorityList);
    }
  };

  const handleBack = () => {
    onPrevious(screeningResponse);
  };

  const handleNext = () => {
    if (selectedCount === 0) {
      setSnackbar({
        open: true,
        message: "Please select at least one feature to proceed.",
        severity: "warning",
      });
      return;
    } else if (selectedCount < 3) {
      setSnackbar({
        open: true,
        message: "You can only select up to 3 features.",
        severity: "warning",
      });
      return;
    } else if (selectedCount > 5) {
      setSnackbar({
        open: true,
        message: "You can only select up to 5 features.",
        severity: "warning",
      });
      return;
    }

    // If no errors, proceed with the selected features
    const selectedFeaturesData = features.flatMap((feature) =>
      feature.subFeatures.length > 0
        ? feature.subFeatures
            .filter((sf) => sf.selected)
            .map((sf) => ({
              featureId: sf.featureId,
              name: sf.featureName,
              image: sf.imageUrl,
            }))
        : feature.selected
        ? [
            {
              featureId: feature.featureId,
              name: feature.featureName,
              image: feature.imageUrl,
            },
          ]
        : []
    );

    console.log(
      "Selected features data in FeaturesAccordion:",
      selectedFeaturesData
    );
    onNext(selectedFeaturesData);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  if (loading) {
    return (
      <div className="loader-container pt-5 pb-5">
        <img src={loadingGif} alt="Loading..." />
      </div>
    );
  }

  return (
    <div className="features-accordion">
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar> */}

      <div className="heading-section">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="form-title">
            Which
            <span className="know"> 5 card features</span> are you interested
            in?
          </h1>
          <h3 className="info-text">
          Choose up to five features, and we'll recommend the best cards for you.
          &nbsp;<span>Note: cashback and reward points features are mutually exclusive in the same card.</span>
          </h3>
          <span className="form-title-underline"></span>
        </div>
        {recommendationsMessage > 0 && (
          <div className="count-chip">{recommendationsMessage} card found</div>
        )}
      </div>

      <div className="feature-block">
        {features.map((feature, index) =>
          feature.active == "Y" && feature.productType === "Card" ? (
            <div key={feature.featureId} className="feature">
              <button
                className={`feature-heading ${feature.isOpen ? "open" : ""}`}
                onClick={() => toggleFeature(index)}
              >
                <div className="feature-heading-logo-name">
                  {feature.imageUrl && (
                    <img
                      src={getImageUrl(feature.imageUrl)}
                      alt={feature.featureName}
                      className="feature-image"
                    />
                  )}
                  <p>{feature.featureName}</p>
                </div>
                {/*  */}
                <div className="selected-sub-feature-container">
                  <div
                    style={{
                      // width: "0%",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {selectedSubFeaturesForHeader[feature.featureName] &&
                      selectedSubFeaturesForHeader[feature.featureName].map(
                        (sf) => (
                          <span className="selected-subfeatures">
                            {sf}
                            {priorityList.includes(sf) ? (
                              <span className="count">
                                {priorityList.indexOf(sf) + 1}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        )
                      )}
                  </div>
                  <span
                    className={`feature-indicator ${
                      feature.isOpen ? "open" : ""
                    }`}
                  >
                    <img
                      src={feature.isOpen ? openIcon : closeIcon}
                      alt={feature.isOpen ? "Open" : "Close"}
                    />
                  </span>
                </div>
              </button>
              <div
                className={`feature-content ${feature?.isOpen ? "open" : ""}`}
              >
                {feature?.subFeatures?.length > 0 ? (
                  feature?.subFeatures?.map((subFeature, subIndex) =>
                    subFeature.active === "Y" ? (
                      <button
                        key={subFeature.featureId}
                        className={`sub-feature ${
                          subFeature.selected ? "selected" : ""
                        }`}
                        onClick={() =>
                          selectSubFeature(
                            subFeature.featureName,
                            index,
                            subIndex
                          )
                        }
                      >
                        <span className="sub-feature-icon">
                          {subFeature.imageUrl && (
                            <ReactSVG
                              src={getImageUrl(subFeature.imageUrl)}
                              beforeInjection={(svg) => {
                                svg.classList.add("sub-feature-image");
                                // svg.setAttribute("width", "100%"); // Set width to 100% of container
                                // svg.setAttribute("height", "100%");
                              }}
                            />
                          )}
                          <br></br>
                        </span>
                        <span className="sub-feature-name">
                          {subFeature.featureName}
                        </span>
                      </button>
                    ) : (
                      ""
                    )
                  )
                ) : (
                  <button
                    className={`sub-feature ${
                      feature.selected ? "selected" : ""
                    }`}
                    onClick={() => selectSubFeature(index, -1)}
                  >
                    {feature.featureName}
                  </button>
                )}
              </div>
            </div>
          ) : (
            ""
          )
        )}
      </div>

      {/* <div className="feature-section-actions">
        <button className="back-btn" onClick={onPrevious}>
          Back
        </button>
        <button className="feature-section-next-btn" onClick={handleNext}>
          Next
        </button>
      </div> */}
    </div>
  );
};

export default FeaturesAccordion;
