import React, { useEffect } from "react";
import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "./PrivacyPolicyBottomSheet.css";
// import privacyContentJSON from "../../Constants/PrivacyPolicy.json";
import { Link, useNavigate } from "react-router-dom";

function PrivacyPolicyBottomSheet({ onClose, open, setOpen, onAgree }) {

  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);

  const [JSONData, setJSONData] = useState({});
  useEffect(() => {
    if (process.env.REACT_APP_REGION === "PK") {
      import('../../Constants/PrivacyPolicyPK.json').then(data => {
      setJSONData(data.default);
    });
  } else {
      import('../../Constants/PrivacyPolicy.json').then(data => {
      setJSONData(data.default);
    });
  }
  }, []);

  const { privacy_info } = JSONData;

  const navigate = useNavigate();

  const handleDragStart = (event) => {
    setIsDragging(true);
    setStartY(event.clientY);
  };

  const handleDragEnd = (event) => {
    setIsDragging(false);
    if (event.clientY - startY > 50) {
      setOpen(false);
    }
  };

  const snapPoints = [0.8, 0.8]; // Specify your snap points here (e.g., 50% and 80% of the screen height)

  return (
    <BottomSheet
      open={open}
      snapPoints={({ maxHeight }) => [
        maxHeight * snapPoints[0],
        maxHeight * snapPoints[1],
      ]}
      header={<h2>Privacy Policy</h2>}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onResize={(resizeEvent) => {
        // console.log("resizeEvent", resizeEvent);
      }}
      onSpringStart={async (event) => {
        if (event.type === "CLOSE") {
          setOpen(false);
        }
      }}
      style={{
        cursor: isDragging ? "grabbing" : "default",
      }}
    >
      <div className="privacy-policy-content">
        {privacy_info?.map((pri_item, index) => {
          return (
            <div key={index}>
              {pri_item.title && (
                <h5 style={{ fontWeight: "bold" }}>{pri_item.title}</h5>
              )}
              {pri_item.contents.map((pr_cn, idx) => (
                <p key={idx}>{pr_cn}</p>
              ))}
            </div>
          );
        })}

        <div className="privacypolicy-selection-actions">
          <Link
            to="#"
            className="back-btn"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          >
            Close
          </Link>
          <button
            className="helpandsupport-submit-btn"
            type="submit"
            onClick={() => {
              setOpen(false);
              onAgree();
            }}
          >
            Agree & Continue
          </button>
        </div>
      </div>
    </BottomSheet>
  );
}

export default PrivacyPolicyBottomSheet;
