import "react-international-phone/style.css";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";

let allowedCountries;

if (process.env.REACT_APP_REGION === "PK") {
  allowedCountries = ["pk"];
}
else {
  allowedCountries = ["ae", "sa"]; // Use the correct ISO2 codes
}

export const MuiPhone = ({
  countryCode,
  error,
  value,
  onChange,
  selectedDialCode,
  setselectedDialCode,
  ...restProps
}) => {
  const { selectedCountry, setselectedCountry } =
    useContext(ApplicationIdContext);

  const [defaultCon, setdefaultCon] = useState(selectedCountry);
  const filteredCountries = defaultCountries.filter((country) => {
    const parsedCountry = parseCountry(country);
    return allowedCountries.includes(parsedCountry?.iso2);
  });

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: defaultCon
        ? defaultCon
        : localStorage.getItem("selectedCountry"),
      value,
      countries: filteredCountries,
      onChange: (data) => {
        const {
          country: { dialCode, iso2 },
          phone,
        } = data;
        setselectedDialCode(dialCode);
        setselectedCountry(iso2);
        onChange(phone);
      },
    });

  useEffect(() => {
    setCountry(countryCode);
  }, [countryCode]);

  // Helper function to remove the "+" sign from the input value
  const getDisplayValue = (inputValue, dialCode) => {
    if (inputValue.startsWith(`+${dialCode}`)) {
      return inputValue.slice(dialCode.length + 1).replace(/^\+/, "");
    }
    return inputValue.replace(/^\+/, "");
  };
  useEffect(() => {
    console.log("selectedCountry>>>>>>", selectedCountry);
    setdefaultCon(selectedCountry);
    setCountry(selectedCountry);
  }, [selectedCountry]);

  return (
    <TextField
      error={error ? true : false}
      helperText={error ? error : ""}
      variant="outlined"
      color="primary"
      placeholder="Phone number"
      // value={inputValue}
      value={getDisplayValue(inputValue, selectedDialCode)} // Remove the dial code from the input value
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      style={{ border: "none" }}
      className="phone-number-field"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              MenuProps={{
                style: {
                  height: "300px",
                  width: "360px",
                  top: "10px",
                  left: "-34px",
                  border: "none",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country?.iso2 || ""}
              readOnly
              onChange={(e) => {
                setselectedCountry(e.target.value);
                setCountry(e.target.value);
              }}
              renderValue={(value) => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FlagImage iso2={value} style={{ display: "flex" }} />
                  {selectedDialCode && (
                    <Typography style={{ padding: "0px 5px" }}>
                      {`+${selectedDialCode}`}
                    </Typography>
                  )}
                </div>
              )}
            >
              {filteredCountries.map((c) => {
                const parsedCountry = parseCountry(c);
                return parsedCountry ? (
                  <MenuItem key={parsedCountry.iso2} value={parsedCountry.iso2}>
                    <FlagImage
                      iso2={parsedCountry.iso2}
                      style={{ marginRight: "8px" }}
                    />
                    <Typography marginRight="8px">
                      {parsedCountry.name}
                    </Typography>
                    <Typography color="gray">
                      +{parsedCountry.dialCode}
                    </Typography>
                  </MenuItem>
                ) : null;
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
