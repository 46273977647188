import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import "./CardRecommendations.css";
import LoanItem from "./LoanItem";
import ConfirmationModal from "./ConfirmationModal";
import SubmitModal from "./SubmitModal";
import backArrow from "../../images/back-arrow.svg";
import useAxiosInstance from '../../api/axios';
import { useNavigate } from "react-router-dom";
import ViewMoreButton from './ViewMoreButton';
import { useMediaQuery } from "react-responsive";
import SendEmail from '../../utils/emailService';

const LoanRecommendations = ({
  countryCode,
  loanData,
  onPrevious,
  setSnackbar,
  selectedCard,
  setSelectedCard,
}) => {

  console.log(loanData);

  const axios = useAxiosInstance();

  const isMobile = useMediaQuery({ query: "(max-width: 431px)" });
  
  const [customerData, setCustomerData] = useState(null);
  const [location, setLocation] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const [productImages, setProductImages] = useState({});
  const [currency, setCurrency] = useState("AED");
  const [modalOpen, setModalOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const { applicationId } = useContext(ApplicationIdContext);
  const navigate = useNavigate();

  const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;


  useEffect(() => {
    if (countryCode === "sa") {
      setCurrency("SAR");
    } else if (countryCode == "pk") {
      setCurrency("PKR");
    } else {
      setCurrency("AED");
    }
  }, [currency]);

  useEffect(() => {
    loanData.map((card) => {
      getProductImageData(card.productId);
    });
    
    if (loanData.length > 10) {
      const element = document.getElementById(`card-${loanData.length - 10}`);
      element.scrollIntoView({ behavior: 'smooth'});
    }
  }, [loanData]);


  const getProductImageData = async (productId) => {
    try {
      axios
        .get(`/api/products/public/image/${productId}`, {
          headers: {
            "Content-Type": "image/jpeg",
          },
          responseType: "blob",
        })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          setProductImages((prevState) => ({
            ...prevState,
            [productId]: imageUrl,
          }));
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } catch (error) {
      return "invalid";
    }
  };



  const getImageUrl = (imagePath) => {
    return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
  };

  const handleSelect = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
  };

  const handleViewMoreCards = () => {
    // fetchCards(10, pageNum + 1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  


  return (
    <div className="card-recommendations-container">
      <div className="heading-section">

        <div>
        <h1 className="form-title">
        Here are the best <span className="know">Loan Offers</span> for you
        </h1>
        <h3 className="info-text">
        Based on your profile, you’re eligible for the following loan amounts.
          </h3>
        <span className="form-title-underline"></span>
        </div>
        {isMobile ? '' : <button className="reset-button" onClick={onPrevious}><img src={backArrow} alt="Reset Filter"/><span>Return back Home</span></button>}

      </div>
    
      <div className="cards-container">
        {loanData?.length !== 0 &&
          loanData?.map((card, index) => (
            <LoanItem 
            key={index} 
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            card={card}
            index={index}
            productImages={productImages}
            setSnackbar={setSnackbar}
            customerData={customerData}
            // selectedSubFeatures={selectedSubFeatures}
            location={location}
            countryCode={countryCode}
            />
          ))}

          
          <SubmitModal open={submitModalOpen} />

      </div>

      {/* <div className="view-more-cards-container">
        <span onClick={handleViewMoreCards}>
          View More Options <i className="arrow down"></i>
        </span>
      </div> */}

    </div>
  );
};

export default LoanRecommendations;
