import React, { useState, useContext, useEffect } from "react";
import useAxiosInstance from '../../api/axios';
import "./LoanItem.css";
import LoanConfirmationModal from "./LoanConfirmationModal";
import SendEmail from '../../utils/emailService';
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import { useNavigate } from "react-router-dom";

import cbd from "../../images/cbd.svg";
import applyIcon from "../../images/apply-icon.svg";
import meezanBank from "../../images/MeezanBank.svg";

const LoanItem = ({selectedCard, setSelectedCard, card, index, productImages, setSnackbar, customerData, selectedSubFeatures, location, countryCode}) => {

    const [currency, setCurrency] = useState("AED");
    const [modalOpen, setModalOpen] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);

    const { applicationId } = useContext(ApplicationIdContext);
    const navigate = useNavigate();

    const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (countryCode === "sa") {
          setCurrency("SAR");
        } else if (countryCode == "pk") {
          setCurrency("PKR");
        } else {
          setCurrency("AED");
        }
    }, [currency]);

    const getImageUrl = (imagePath) => {
        return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
    };
    
    const handleSelect = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSubmitApplication = async () => {
        // Check if a card is selected
    
        if (!selectedCard) {
          setSnackbar({
            open: true,
            title: "Card Selection",
            message: "Please select a card first to apply.",
            severity: "error",
          });
          return;
        }
    
        // setModalOpen(true);
        // setTimeout(() => {
        //   window.open(selectedCard.routingUrl, "_blank");
        //   setModalOpen(false);
        // }, 2000);
    
        const payload = {
          applicationId: applicationId,
          customer: {
            customerId: customerData?.customerId,
          },
          location: location,
          product: {
            productId: selectedCard.productId,
          },
          applicationType: "Loan",
          clientRiskProfile: customerData?.clientRiskProfile,
          status: "Submitted",
        };
    
        try {
          const response = await axios.put("/api/applications/public", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data) {
            console.log("Application submitted:", response.data);
          }
          
    
          // If the API call is successful, and you have a routing URL, redirect the user
          if (response.data?.product?.routingUrl) {
            // window.open(response.data.product.routingUrl, "_blank");
            setModalOpen(false);
            setSubmitModalOpen(true);
            setTimeout(() => {
              window.open(selectedCard.routingUrl, "_blank");
              setSubmitModalOpen(false);
              navigate("/congratulations");
            }, 2000);
          }
          await SendEmail(
            response.data.customer,
            applicationId,
            response.data.product.productName
          );
        } catch (error) {
          console.error("Error submitting application:", error);
          // Handle the error, show a message to the user if needed
        } finally {
          // Hide the loading indicator
          setSubmitModalOpen(false);
        }
      };

    const axios = useAxiosInstance();

    return (
        <div>
        <div
          id={`card-${index}`}
          className={`card-item ${
            selectedCard && selectedCard?.productId === card?.productId
              ? "selected"
              : ""
          }`}
          key={card.productId}
          onClick={() => handleSelect(card)}
        >
         
          {/* <div className="card-content"> */}
            <div className="card-image-wrapper">
              <img
                src={productImages[card.productId]}
                className="card-image"
              />
            </div>

            <div className="card-info-mobile loan">
              <div className="card-highlights">
                  <h3 className="card-title">{card.productName}</h3>
              </div>

              <div className="loan-terms">
                <div className="tenure"><span>Tenure:</span> {card.loanTenure}</div>
                <div className="loan-amount"><span>Loan Amount:</span> {card.loanAmountTxt}</div>
                <div className="processing-fee"><span>Processing Fee:</span> {card.processingFee}</div>
                <div className="interest-rate"><span>Interest Rate:</span> {card.interest}</div>
              </div>
            </div>

            <div className="card-info">

              <div className="card-highlights">
                <h3 className="card-title">{card.productName}</h3>
              </div>

              <div className="loan-terms">
                <div className="tenure"><span>Tenure:</span> {card.loanTenure}</div>
                <div className="loan-amount"><span>Loan Amount:</span> {card.loanAmountTxt}</div>
                <div className="processing-fee"><span>Processing Fee:</span> {card.processingFee}</div>
                <div className="interest-rate"><span>Interest Rate:</span> {card.interest}</div>
              </div>


            </div>


            <div className="card-actions">
                <img className="apply-btn" src={applyIcon} alt="Apply" onClick={() => handleSelect(card)} />
            </div>

            </div>

            <LoanConfirmationModal
              productImages={productImages}
              meezanBank={meezanBank}
              open={modalOpen}
              card={card}
              features={card.features}
              secondaryBtnText={"Choose another option"}
              handleSecondaryBtn={handleCloseModal}
              primaryBtnText={"Apply now"}
              handlePrimaryBtn={handleSubmitApplication}
              currency={currency}
            />
          {/* </div> */}
        </div>
    );
};

export default LoanItem;