import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa"; // Ensure you have `react-icons` installed
import line from "../../images/yellow line.png";
import termsOfServiceContainerImg from "../../images/terms-of-service-container-side-img.png";
import "./TermsOfService.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import success from "../../images/ic24-success.png";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "@mui/material";
import axios from "../../api/axios";
import PrivacyPolicyBottomSheet from "../PrivacyPolicyBottomSheet/PrivacyPolicyBottomSheet";
// import TermsOfCondidtionJSON from "../../Constants/TermsOfService.json";

function Modal({ onClose }) {
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close-btn" onClick={onClose}>
          ×
        </button>
        <div className="modal-header"></div>
        <img className="success" src={success} alt="Logo"></img>
        <div className="modal-body">
          <p>
            Thank you for reaching out! Your inquiry has been successfully
            submitted. Our team aims to respond to inquiries within 2-3 business
            days.{" "}
          </p>
          <p>Thank you for your patience! :)</p>
        </div>
      </div>
    </div>
  );
}

function TermsOfService() {
  window.scrollTo(0,0);
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  const [showModal, setShowModal] = useState(false); // Correct placement
  const isMobile = useMediaQuery({ query: "(max-width: 426px)" });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [JSONData, setJSONData] = useState({});
  useEffect(() => {
    if (process.env.REACT_APP_REGION === "PK") {
      import('../../Constants/TermsOfServicePK.json').then(data => {
      setJSONData(data.default);
    });
  } else {
      import('../../Constants/TermsOfService.json').then(data => {
      setJSONData(data.default);
    });
  }
  }, []);

  const { terms_of_service_info } = JSONData;

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    localStorage.setItem("terms-of-service", true);
    navigate(-1);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="terms-of-service-container">
        <span className="terms-of-service-page-title-2">
          <h1 className="form-title">
            Terms Of <span className="know">Service</span>
          </h1>
        </span>
        <div className="terms-section-container">
          <img
            src={termsOfServiceContainerImg}
            alt="terms_of_service_container_img"
          />

          <div className="terms-of-service-section-container ">
            <div className="terms-of-service-section ml-4">
              <span className="terms-of-service-page-title-1">
                <h1 className="form-title">
                  Terms Of <span className="know">Service</span>
                </h1>
                <span className="form-title-underline"></span>
              </span>
            </div>

            <div className="terms-service-content">
              {terms_of_service_info?.map((pri_item, index) => {
                return (
                  <div key={index}>
                    {pri_item.Heading && (
                      <h5 style={{ fontWeight: "bold" }}>{pri_item.Heading}</h5>
                    )}
                    <p>{pri_item.Content}</p>
                    {pri_item.options && (
                      <>
                        <p>{pri_item.options.tagLine}</p>
                        <ul>
                          {pri_item.options.list &&
                            pri_item.options.list.map((pr_cn, idx) => (
                              <li key={idx}>{pr_cn}</li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {showModal && <Modal onClose={closeModal} />}
        {privacyPolicyModal && (
          <PrivacyPolicyBottomSheet
            open={privacyPolicyModal}
            setOpen={setPrivacyPolicyModal}
          />
        )}
        <div className="terms-of-service-selection-actions">
          <button
            onClick={() => navigate(-1)}
            className="terms-of-service-selection-back-to-home"
          >
            Back
          </button>
          <button
            className="terms-of-service-next-btn"
            type="submit"
            onClick={handleSubmit}
          >
            Agree and Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default TermsOfService;
