import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
  useEffect,
} from "react";
import "./Form.css";
import { ThreeDots } from "react-loader-spinner";
import leftsideImage from "../../images/bg-1.png";
import { MuiPhone } from "../PhoneNumberInput/MuiPhone";
import { TextField } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Form = forwardRef(
  (
    {
      countryCode,
      formData,
      setFormData,
      onNext,
      onPrevious,
      onApplicationIdCreated,
    },
    ref
  ) => {
    // const [countryCode, setCountryCode] = useState("");

    useImperativeHandle(ref, () => ({
      setCountry(country) {
        // setCountryCode(country);
      },
    }));

    const isMobile = useMediaQuery({ query: "(max-width: 426px)" });
    const [selectedDialCode, setselectedDialCode] = useState("");

    const handleNameChange = (event) => {
      const newFullName = event.target.value.replace(/[^a-zA-Z ]/g, "");
      setFormData({ ...formData, fullName: newFullName });
    };

    const handleEmailChange = (event) => {
      setFormData({ ...formData, email: event.target.value });
    };

    const handlePhoneChange = (value) => {
      const newPhoneValue = value.replace(/[^\d]/g, "");
      setFormData({ ...formData, phone: newPhoneValue });
      // setFormData({ ...formData, dialCode: selectedDialCode });
    };

    const handlePromocodeChange = (event) => {
      setFormData({ ...formData, promoCode: event.target.value });
    };

    useEffect(() => {
      if (selectedDialCode)
        setFormData({ ...formData, dialCode: selectedDialCode });
    }, [selectedDialCode]);

    return (
      <form>
        {countryCode.length > 0 ? (
          <div>
            <div className="mobile-form-title">
              <h1 className="form-title">
                <span>Let’s get to </span>
                <span className="know">know</span> you
              </h1>
              <span className="form-title-underline"></span>
            </div>
            <div className="form-container">
              <div className="w-50 left-side">
                <img src={leftsideImage} alt="Get Instant Cashback" />
              </div>

              <div className="right-side">
                <h1 className="form-title">
                  <span>Let’s get to </span>
                  <span className="know">know</span> you
                </h1>
                <span className="form-title-underline"></span>

                <div>
                  <TextField
                    error={formData.errors?.fullName ? true : false}
                    id="fullName"
                    size={isMobile ? "small" : "normal"}
                    fullWidth
                    label="Full Name"
                    variant="outlined"
                    margin="normal"
                    value={formData.fullName}
                    onChange={handleNameChange}
                    className="custom-textField input" // Combine custom and existing classes
                    helperText={
                      formData.errors?.fullName ? formData.errors.fullName : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    size={isMobile ? "small" : "normal"}
                    label={"Email"}
                    type="email"
                    value={formData.email}
                    margin="normal"
                    onChange={handleEmailChange}
                    error={formData.errors?.email ? true : false}
                    helperText={
                      formData.errors?.email ? formData.errors?.email : ""
                    }
                  />
                </div>
                <div>
                  <MuiPhone
                    countryCode={countryCode}
                    error={formData.errors?.phone}
                    fullWidth
                    size={isMobile ? "small" : "normal"}
                    margin="normal"
                    placeholder="Phone Number "
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    selectedDialCode={selectedDialCode}
                    setselectedDialCode={setselectedDialCode}
                  />
                </div>

                <TextField
                  fullWidth
                  size={isMobile ? "small" : "normal"}
                  error={formData.errors?.promoCode ? true : false}
                  id="promoCode"
                  label="Merchant / Promo Code"
                  variant="outlined"
                  value={formData.promoCode}
                  margin="normal"
                  onChange={handlePromocodeChange}
                  className="promoCode"
                  helperText={
                    formData.errors?.promoCode ? formData.errors?.promoCode : ""
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <ThreeDots
            visible={true}
            height="520"
            width="80"
            color="#711c60"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass="threedots-loading"
          />
        )}
      </form>
    );
  }
);

export default Form;
