// src/utils/emailTemplate.js

const emailTemplate = (customerData, applicationId, appliedProductName) => `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Your Loan Application Received</title>
    <style>
        body {
        color: #2F1329;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
            margin: 0;
            padding: 0;
            background-color: #ffffff;
        }
        .email-container {
            width: 100%;
            margin: auto;
            background-color: #ffffff;
        }
        .header {
            text-align: center;
            padding: 20px;
        }
        .content {
            padding:  20px 60px;
        }
        .content h1 {
            color: #2F1329;
        }
        .content p {
            font-size: 16px;
margin-top:0px;
            color: #2F1329;
            line-height: 1.5;
        }
        .content strong {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #2F1329;
        }
        .application-process {
            display: flex;
            justify-content: space-between;
            margin: 20px 0px 0px 7px;

        }
        .application-process div {
            font-size: 16px;

            width: 23%;
            color: #4B3049 !important;
        }
        .application-details {
            margin: 20px 0px;
        }
        .application-details ul {
            padding: 0;
        }
        .application-details li {
            font-size: 16px;

            color: #4B3049;
            margin-bottom: 10px;
        }
        .application-details li strong {
            color: #4B3049;
        }
        .footer {
            background: #FAF6F9;
            padding: 20px;
            text-align: center;
            color: #2F1329;
        }
        .cta {
            display: inline-block;
            padding: 10px 20px;
            background-color: #4CAF50;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            margin-top: 20px;
        }
        .social-icons img {
            max-width: 30px;
            margin: 0 10px;
        }
        @media screen and (max-width: 600px) {
            .content {
                padding: 10px;
            }
            .application-details li {
                font-size: 14px;
            }
        }
    </style>
</head>
<body>
    <div class="email-container">
        <div class="header">
            <div><img src="cid:logo01" style="width: 400px" alt=${process.env.REACT_APP_TITLE}></div>
            <div><img src="cid:lastStep1" alt="Celebration" style="margin-top: 20px;width: 580px"></div>
        </div>
        <div class="content">
            <h1>Hi ${customerData.name}!</h1>
            <p>Thank you for choosing ${process.env.REACT_APP_TITLE} to apply for loan. We are pleased to confirm that your application has been successfully received and is currently being processed.</p>
            <br />
            <strong>Application Process</strong>
            
            <img src="cid:progressBar" alt="Progress Bar" style="max-width: 100%;">
            <p>Your estimated processing time is:<strong>3-4 working days</strong></p>
            <div class="application-details">
                <ul>
                    <li>Application ID:<strong> ${applicationId}</strong></li>
                    <li>Applied Loan:<strong> ${appliedProductName}</strong></li>
                </ul>
            </div>
            <p>Please note that the processing time may vary depending on the bank's internal procedures. Rest assured, our team is working diligently to expedite the process for you.</p>
            <p>If you have any questions or require further assistance, feel free to reach out to our support team at <a href="mailto:support@${process.env.REACT_APP_DOMAIN}" style="color: #5B7194;">support@${process.env.REACT_APP_DOMAIN}</a>. We're here to help!</p>
            <br>
            <p>Thank you for choosing<strong> ${process.env.REACT_APP_TITLE}.</strong></p>
            <br>
            <p>Best Regards,<br>${process.env.REACT_APP_TITLE}</p>
            <h2 style="color: #711C60 !important; text-align: center;margin-top:120px;">Visit the ${process.env.REACT_APP_TITLE} website!</h2>
            <p style="text-align: center;font-style: normal;font-weight: 600;font-size: 20px;line-height: 32px;"></p>
            <p style="text-align: center;font-style: normal;color: #2F1329;font-style: normal;font-weight: 400;font-size: 18px;line-height: 32px;">Get the most of ${process.env.REACT_APP_TITLE} by visiting the website. You can log in by using your existing emails address and password.</p>
            <a href="https://www.${process.env.REACT_APP_DOMAIN}" class="cta" style="display: block; text-align: center;  color: white;  text-decoration: none; margin: 20px auto;box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030C28 !important;border-radius: 4px;background: #711C60;padding: 16px 32px;font-weight:500;width: 200px;">Visit Website</a>
        </div>
        <div class="footer">
            <p>We hate to see you go, but if you must <a href="#" style="color: #5B7194;">unsubscribe</a></p>
            <p>Copyright &copy; 2024 ${process.env.REACT_APP_TITLE}. All rights reserved.</p>
        </div>
    </div>
</body>
</html>
`;

export default emailTemplate;