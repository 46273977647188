import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import Chip from "@mui/material/Chip";
import MenuIcon from "@mui/icons-material/Menu";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import "./CardRecommendations.css";
import CardItem from "./CardItem";
import ConfirmationModal from "./ConfirmationModal";
import SubmitModal from "./SubmitModal";
import star from "../../images/star.png";
import union from "../../images/Union.png";
import applyIcon from "../../images/apply-icon.svg";
import backArrow from "../../images/back-arrow.svg";
import bankIcon from "../../images/bank-icon.svg";
import warningIcon from "../../images/warning-icon.svg";
import useAxiosInstance from '../../api/axios';
import { useNavigate } from "react-router-dom";
import ViewMoreButton from './ViewMoreButton';

import { useMediaQuery } from "react-responsive";
import SendEmail from '../../utils/emailService';

const CardRecommendations = forwardRef(({
  selectedFeatureIds,
  countryCode,
  cardData,
  onPrevious,
  selectedSubFeatures,
  selectedCard,
  setSelectedCard,
  setSnackbar,
}, ref) => {
  console.log("CardRecommendations>>>>>>>>>>>", {
    cardData,
    selectedFeatureIds,
  });

  const axios = useAxiosInstance();

  const isMobile = useMediaQuery({ query: "(max-width: 431px)" });
  
  const [customerData, setCustomerData] = useState(null);
  const [location, setLocation] = useState(null);
  const [featureNames, setFeatureNames] = useState([]);
  const [recommendedCards, setRecommendedCards] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [productImages, setProductImages] = useState({});
  const [currency, setCurrency] = useState("AED");
  const [modalOpen, setModalOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const { applicationId } = useContext(ApplicationIdContext);
  const navigate = useNavigate();

  const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalOpen(true);
    },
    closeModal() {
      setModalOpen(false);
    }
  }));

  useEffect(() => {
    if (cardData && cardData?.length !== 0) {
      const features = getAllFeaturesAndSubFeatures(cardData);
      setFeatureNames(features);
      let filteredCardsArr = getFilteredFeaturesCards(cardData);
      setRecommendedCards(filteredCardsArr);
    }
  }, [cardData]);

  useEffect(() => {
    if (countryCode === "sa") {
      setCurrency("SAR");
    } else if (countryCode == "pk") {
      setCurrency("PKR");
    } else {
      setCurrency("AED");
    }
  }, [currency]);

  useEffect(() => {
    const fetchApplicationData = async () => {
      if (!applicationId) return;

      try {
        const response = await axios.get(`/api/applications/public/${applicationId}`);
        console.log("Application data:", response.data);
        setLocation(response.data.location);

        const customerInfo = response.data.customer;
        if (customerInfo) {
          setCustomerData(customerInfo);
          // console.log("Customer data:", customerInfo);
        } else {
          console.error("Customer data not found in the application details");
        }
      } catch (error) {
        console.error("Failed to fetch application data:", error);
      }
    };

    fetchApplicationData();
  }, [applicationId]);

  useEffect(() => {
    console.log(recommendedCards);
    recommendedCards.map((card) => {
      getProductImageData(card.productId);
    });
    
    if (recommendedCards.length > 10) {
      const element = document.getElementById(`card-${recommendedCards.length - 10}`);
      element.scrollIntoView({ behavior: 'smooth'});
    }
  }, [recommendedCards]);

  const getFilteredFeaturesCards = (cards) => {
    let filteredCardsArr = Array.from(cards);
      filteredCardsArr.forEach(product => {
        product.features = product.features.filter(feature => {
          return selectedFeatureIds.includes(feature.featureId);
        })
      });
      return filteredCardsArr;
  };

  const fetchCards = async (numberOfCards, page) => {
    console.log("Fetching 10 more cards...");

    if (!applicationId) return;

    try {
      const response = await axios.get(
        `/api/recommendations/public/${applicationId}/filter`,
        {
          params: {
            page,
            size: numberOfCards,
            preferences: selectedFeatureIds + "",
          },
        }
      );
      console.log("Cards data:", response.data.content);

      if (response.data.empty) {
        setSnackbar({
          open: true,
          title: "Recommended Cards",
          message: "No more cards to display.",
          severity: "error",
        });
      }
      setPageNum(pageNum + 1);

      let filteredCardsArr = getFilteredFeaturesCards(response.data.content);

      if (response.data.content.length > 0) {
        setRecommendedCards((prevState) => [
          ...prevState,
          ...filteredCardsArr,
        ]);
      }
    } catch (error) {
      console.error("Failed to fetch application data:", error);
    }
  };

  const getProductImageData = async (productId) => {
    try {
      axios
        .get(`/api/products/public/image/${productId}`, {
          headers: {
            "Content-Type": "image/jpeg",
          },
          responseType: "blob",
        })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          setProductImages((prevState) => ({
            ...prevState,
            [productId]: imageUrl,
          }));
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } catch (error) {
      return "invalid";
    }
  };

  const getAllFeaturesAndSubFeatures = (cards) => {
    if (!cards || cards.length === 0) return [];

    const allFeatures = cards.flatMap((card) =>
      card.features.flatMap((feature) =>
        feature.subFeatures
          ? feature.subFeatures.length > 0
            ? feature.subFeatures.map(
                (subFeature) =>
                  `${feature.featureName}: ${subFeature.featureName}`
              )
            : [feature.featureName]
          : []
      )
    );
    return [...new Set(allFeatures)];
  };

  const getImageUrl = (imagePath) => {
    return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
  };

  const handleSelect = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
  };

  const handleViewMoreCards = () => {
    fetchCards(10, pageNum + 1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitApplication = async () => {
    // Check if a card is selected

    if (!selectedCard) {
      setSnackbar({
        open: true,
        title: "Card Selection",
        message: "Please select a card first to apply.",
        severity: "error",
      });
      return;
    }

    // setModalOpen(true);
    // setTimeout(() => {
    //   window.open(selectedCard.routingUrl, "_blank");
    //   setModalOpen(false);
    // }, 2000);

    const payload = {
      applicationId: applicationId,
      customer: {
        customerId: customerData?.customerId,
      },
      location: location,
      product: {
        productId: selectedCard.productId,
      },
      productType: "Card",
      clientRiskProfile: customerData?.clientRiskProfile,
      status: "Submitted",
    };

    try {
      const response = await axios.put("/api/applications/public", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        console.log("Application submitted:", response.data);
      }
      

      // If the API call is successful, and you have a routing URL, redirect the user
      if (response.data?.product?.routingUrl) {
        // window.open(response.data.product.routingUrl, "_blank");
        setModalOpen(false);
        setSubmitModalOpen(true);
        setTimeout(() => {
          window.open(selectedCard.routingUrl, "_blank");
          setSubmitModalOpen(false);
          navigate("/congratulations");
        }, 2000);
      }
      await SendEmail(
        customerData,
        applicationId,
        selectedSubFeatures,
        selectedCard.productName
      );
    } catch (error) {
      console.error("Error submitting application:", error);
      // Handle the error, show a message to the user if needed
    } finally {
      // Hide the loading indicator
      setSubmitModalOpen(false);
    }
  };

 

  return (
    <div className="card-recommendations-container">
      <div className="heading-section">

        <div>
        <h1 className="form-title">
          Select your card & enjoy the <span className="know">benefits</span>
        </h1>
        <h3 className="info-text">
        Here are the list of cards based on your preference. The card with the best possible match are displayed first.
          </h3>
        <span className="form-title-underline"></span>
        </div>
        {isMobile ? '' : <button className="reset-button" onClick={onPrevious}><img src={backArrow} alt="Reset Filter"/><span>Reset Filter</span></button>}

        {/* <div className="d-flex justify-content-between">
          <div className="feature-chips-container">
            {featureNames.map((feature, index) => (
              <Chip key={index} label={feature} className="feature-chip" />
            ))}
          </div>
        </div> */}
      </div>
      {/* <div className="notification-container">
        <img src={union} alt="features not available"></img>
        <span className="notification-text pr-2">
          Some features are not available right now
        </span>
      </div> */}
      <div className="cards-container">
        {recommendedCards?.length !== 0 &&
          recommendedCards?.map((card, index) => (
            <CardItem 
            key={index} 
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            card={card}
            index={index}
            productImages={productImages}
            setSnackbar={setSnackbar}
            customerData={customerData}
            selectedSubFeatures={selectedSubFeatures}
            location={location}
            countryCode={countryCode}
            />
          ))}

          
          <SubmitModal open={submitModalOpen} />

        {/* {modalOpen && (
          <div className="modal-overlay">
            <div className="modal-box">
              <Typography
                variant="h6"
                component="h2"
                style={{ marginTop: "20px", fontWeight: "bold" }}
              >
                Please wait while you are being redirected to a partner website
                for Credit Card where you can apply.
              </Typography>
              <CircularProgress />
              <Typography style={{ marginTop: "10px" }}>
                Please do not close or leave this page.
              </Typography>
            </div>
          </div>
        )} */}
      </div>

      <div className="view-more-cards-container">
        <span onClick={handleViewMoreCards}>
          View More Cards <i className="arrow down"></i>
        </span>
      </div>

      {/* <div className="recommended-section-actions">
        <button className="back-btn" onClick={onPrevious}>
          Back
        </button>
        <button
          className="feature-section-next-btn"
          onClick={handleSubmitApplication}
          disabled={!selectedCard}
        >
          Apply Now
        </button>
      </div> */}
    </div>
  );
});

export default CardRecommendations;
