import React from "react";
import "./LoanConfirmationModal.css";
import bankIcon from "../../images/bank-icon.svg";
import greenCheck from "../../images/green-check.svg";
import closeIcon from "../../images/close-icon.svg";

const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

const getImageUrl = (imagePath) => {
    return imagePath ? `${serverBaseUrl}/api/images/public/${imagePath}` : ""; // If you have a default image, specify it in the else part
  };

const LoanConfirmationModal = ({ open, card, features, productImages, meezanBank, secondaryBtnText, handleSecondaryBtn, primaryBtnText, handlePrimaryBtn, currency }) => {

    const description = card.description.split("\\n");

    return (
      <>
        {open && (
          <div className="modal-overlay">
            <div className="modal-box loan-confirmation-modal">

            <div className="modal-header">
                <div className="modal-title">
                    <h4>Confirm details and <span>apply</span></h4>
                    <span className="title-underline"></span>
                </div>
                <img src={closeIcon} alt="Close" onClick={handleSecondaryBtn} />
            </div>

              <div className="modal-box-inner">
                
            <div className="card-area">
              <div className="card-image-wrapper">
                 
                  <img
                    src={productImages[card.productId]}
                    alt={card.productName}
                    className="card-image"
                  />
               </div>

                <div className="card-highlights">
                    <h3 className="card-title">{card.productName}</h3>

                    <div className="loan-terms">
                      <div className="tenure"><span>Tenure:</span> {card.loanTenure}</div>
                      <div className="processing-fee"><span>Processing Fee:</span> {card.processingFee}</div>
                      <div className="interest-rate"><span>Interest Rate:</span> {card.interest}</div>
                      <div className="loan-amount"><span>Loan Amount:</span> {card.loanAmountTxt}</div>
                 </div>
                 
                </div>

               

            </div>
            

                {/* <div className="card-features">
                    <h3>Key Features: </h3>
                    <div>
                    {card.features.map(feature => (
                    <span className="card-feature">
                        <img
                        src={getImageUrl(feature.imageUrl)}
                        />
                        <span>{feature.featureName}</span>
                    </span>
                  ))}
                  </div>
                </div> */}

                <div className="product-details">
                    <h3>Description: </h3>
                    {description.map(line => (
                        <div><img className="green-check" src={greenCheck} alt="Check" /><span>{line}</span></div>
                    ))}
                </div>

                <div className="action-buttons">
                  { secondaryBtnText && <button className="select-button back-button" onClick={handleSecondaryBtn}>{secondaryBtnText}</button> }
                  <button className="select-button" onClick={handlePrimaryBtn}>{primaryBtnText}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  export default LoanConfirmationModal;