import React from "react";
import "./StepsIndicator.css";

const StepsIndicator = ({ steps, currentStep }) => {
  return (
    <div className="steps-container">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step-chip ${step.num === currentStep ? "active" : ""} ${
            step.isCompleted ? "completed" : ""
          }`}
          // Removing onClick handler as the parent manages the step change
        >
          {step.name}
        </div>
      ))}
    </div>
  );
};

export default StepsIndicator;
