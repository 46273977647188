import React, { useEffect } from "react";
import "./CongratulationsPage.css"; // Ensure you have the CSS for styling
import CelebrationImage from "../../images/last-step1.png";
import congratulationsImageMb from "../../images/congratulationsImageMb.png";
import { useMediaQuery } from "react-responsive";

function CongratulationsPage({selectedProduct}) {
  const isMobile = useMediaQuery({ query: "(max-width: 431px)" });

  useEffect(() => {
    // Push a new state to the history stack
    window.history.pushState(null, "", window.location.href);

    // Add a popstate event listener to handle the back button
    const handlePopState = (event) => {
      window.location.href = "/";
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="congratulations-container">
      <div className="celebration-text">
        <h1>
          Congratulations! Your {selectedProduct === "Card" ? "credit card" : "loan"} application has been submitted
          successfully
        </h1>
        <p>
          Let us handle the rest. Our dedicated team is working diligently to
          process your application swiftly. Meanwhile, feel free to explore
          other {selectedProduct === "Card" ? "credit card" : "loan"} options!
        </p>
        <button
          onClick={() => (window.location.href = "/")}
          className="backtohome-congrats-page"
        >
          Back to home
        </button>
      </div>

      <div className="celebration-image">
        <img
          src={!isMobile ? CelebrationImage : congratulationsImageMb}
          alt="celebration-img"
        />
      </div>
      <div className="congratulations-actions-container">
        <button
          onClick={() => (window.location.href = "/")}
          className="back-btn"
        >
          Back to home
        </button>
      </div>
    </div>
  );
}

export default CongratulationsPage;
