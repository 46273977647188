import useAxiosInstance from '../api/axios';
import emailTemplate from "./emailTemplate";
import loanEmailTemplate from "./loanEmailTemplate";

const SendEmail = async (customerData, applicationId, appliedProductName, selectedSubFeatures) => {

  const axios = useAxiosInstance();

  const selectedFeatureNames = selectedSubFeatures && selectedSubFeatures.map(feature => feature.name).join(", ");

  const emailPayload = {
    to: customerData.email,
    containImages: true,
    subject: selectedSubFeatures ? 
             "Your Credit Card Application Received" :
             "Your Loan Application Received" ,
    body: selectedSubFeatures ? 
          emailTemplate(customerData, applicationId, selectedFeatureNames, appliedProductName) :
          loanEmailTemplate(customerData, applicationId, appliedProductName),
  };

  console.log("Sending email with payload:", emailPayload);

  try {
    const emailResponse = await axios.post("/api/email/public", emailPayload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Email sent successfully:", emailResponse.data);
  } catch (error) {
    console.error("Error sending email:", error);
    if (error.response) {
      console.error("Error response data:", error.response.data);
    }
  }
};

export default SendEmail;
