// // In ApplicationIdContext.js
// import React, { useState, createContext } from 'react';

// export const ApplicationIdContext = createContext();

// export const ApplicationIdProvider = ({ children }) => {
//   const [applicationId, setApplicationId] = useState(null);

//   return (
//     <ApplicationIdContext.Provider value={{ applicationId, setApplicationId }}>
//       {children}
//     </ApplicationIdContext.Provider>
//   );
// };
import React, { useState, createContext } from "react";

export const ApplicationIdContext = createContext({
  applicationId: null,
  customerId: null,
  setCustomerId: () => {},
  selectedCountryId: null,
  setselectedCountryId: () => {},
  setApplicationId: () => {},
});

export const ApplicationIdProvider = ({ children }) => {
  const [applicationId, setApplicationId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [selectedCountry, setselectedCountry] = useState(null);

  // Now our context provides both applicationId and customerId, and functions to update them
  return (
    <ApplicationIdContext.Provider
      value={{
        applicationId,
        setApplicationId,
        customerId,
        setCustomerId,
        selectedCountry,
        setselectedCountry,
      }}
    >
      {children}
    </ApplicationIdContext.Provider>
  );
};
