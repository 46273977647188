import React, { useState, useEffect, useContext } from "react";
import "./SelectProduct.css";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import useAxiosInstance from "../../api/axios";
import creditCardIcon from "../../images/credit-card-icon.svg"
import cashLoanIcon from "../../images/cash-loan-icon.svg"

const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

function SelectProduct({
  selectedProduct,
  setSelectedProduct,
}) {

  const axios = useAxiosInstance();

  const [banners, setBanners] = useState([]);

  const { applicationId } = useContext(ApplicationIdContext);


  useEffect(() => {

  const fetchBanners = async (event) => {
    try {
          const response = await axios.get(`${serverBaseUrl}/api/banners/public`);
          console.log("Banners:", response.data);
          setBanners(response.data);
      } catch (err) {
          console.error("There was an error fetching the banners:", err);
      }
  }

  fetchBanners();
  }, []);



  const getImageUrl = (imagePath) => {
    return imagePath ? `${serverBaseUrl}/api/images/public/banner/${imagePath}` : ""; // If you have a default image, specify it in the else part
  };

  const handleSelect = (product) => {
    setSelectedProduct(product);

    let product1 = document.getElementsByClassName("product-1")[0];
    let product2 = document.getElementsByClassName("product-2")[0];
    
    if (product === "Card") {
      product1.classList.add("selected");
      product2.classList.remove("selected");
    }
    else {
      product2.classList.add("selected");
      product1.classList.remove("selected");
    }
  };

  // if (!applicationId)
  //   return (
  //     <div>
  //       Application ID is not available. Please start from the beginning.
  //     </div>
  //   );

  return (
    <div className="product-selection-container">
      
      <div className="selection-section ml-4">
        <h1 className="form-title">
          Select a
          <span className="know"> product</span>
        </h1>
        <h3 className="info-text">
        {process.env.REACT_APP_TITLE} will help you find the best option for you.
        </h3>
        <span className="form-title-underline"></span>

        <div style={{ marginTop: "36px", marginBottom: "36px" }}>

              <div className="products">

                <div className={`product product-1 ${selectedProduct === "Card" ? "selected" : " "}`} onClick={() => handleSelect("Card")}>

                  <div className="icon-wrapper">
                    <img src={creditCardIcon} alt="Credit Card icon" />
                  </div>

                  <div className="product-info">
                    <div className="product-title">
                    Do you want to apply for a Credit Card?
                    </div>
                    <div className="product-details">
                    You will get free AED 200 as a special bonus for any credit card you get approved.
                    </div>
                    {/* <button className="select-product-btn" onClick={() => handleSelect("Card")}>
                    {`${selectedProduct === "Card" ? "Selected" : "Select"}`}
                    </button> */}
                  </div>

                </div>

                <div className={`product product-2 ${selectedProduct === "Loan" ? "selected" : " "}`}  onClick={() => handleSelect("Loan")}>
                  
                <div className="icon-wrapper">
                    <img src={cashLoanIcon} alt="Cash Loan icon" />
                  </div>

                  <div className="product-info">
                    <div className="product-title">
                    Do you want to apply for an instant cash loan?
                    </div>
                    <div className="product-details">
                    You will get free AED 200 as a special bonus for any instant cash loan you get approved.
                    </div>
                    {/* <button className="select-product-btn" onClick={() => handleSelect("Loan")}>
                      {`${selectedProduct === "Loan" ? "Selected" : "Select"}`}
                    </button> */}
                  </div>

                </div>

                <div className="banners">
                  {banners.map((banner) => (
                    banner.id == 2 && (
                  <div key={banner.id} className="banner">
                      <div className="banner-image">
                          <img src={getImageUrl(banner.imageUrl)} alt ={banner.title} />
                      </div>
                      <div className="banner-details">
                        <h4 className="banner-title">{banner.title}</h4>
                        <p className="banner-description">{banner.description}</p>
                        <a href={banner.routingUrl} className="banner-link" target="_blank">Apply Now</a>
                      </div>
                    </div>
                    )
                    ))
                  }
                </div>
              </div>

        </div>

      </div>
    </div>
  );
}

export default SelectProduct;
