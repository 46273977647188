import React, { useState, useContext, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApplicationIdContext } from "./contexts/ApplicationIdContext";
import Form from "./components/Form/Form";
import Header from "./components/Header/Header";
import StepIndicator from "./components/StepsIndicator/StepsIndicator";
import CookieConsentPopup from "./components/CookieConsentPopup/CookieConsentPopup";
import SelectProduct from "./components/SelectProduct/SelectProduct";
import EligibilityForm from "./components/EligibilityForm/EligibilityForm";
import CardRecommendations from "./components/CardRecommendations/CardRecommendations";
import LoanRecommendations from "./components/CardRecommendations/LoanRecommendations";
import FeaturesAccordion from "./components/FeaturesAccordion/FeaturesAccordion";
import SubFeatureReview from "./components/SubFeatureReview/SubFeatureReview";
import HelpAndSupport from "./components/HelpAndSupport/HelpAndSupport";
import CongratulationsPage from "./components/CongratulationsPage/CongratulationsPage";
import MuiAlert from "@mui/material/Alert";
import SendEmail from './utils/emailService';
import Modal from "./components/Modal/Modal";
import ErrorModal from "./components/Modal/Modal";
import warningIcon from "./images/warning-icon.svg";

import "./App.css";
import PrivacyPolicyBottomSheet from "./components/PrivacyPolicyBottomSheet/PrivacyPolicyBottomSheet";
import useAxiosInstance from "./api/axios";
import { Snackbar } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import AlertTitle from "@mui/material/AlertTitle";
import TermsOfService from "./components/TermsOfService/TermsOfService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const stepsL = [
  {
    name: "Personal Information",
    className: "Personal-Information",
    num: 0,
  },
  {
    name: "Select Product",
    className: "Select-Product",
    num: 1,
  },
  {
    name: "Check your eligibility",
    className: "Check-your-eligibility",
    num: 2,
  },
  {
    name: "Select Card Feature",
    className: "Select-Card-Feature",
    num: 3,
  },
  {
    name: "Select Card & Apply",
    className: "Select-Card-Apply",
    num: 4,
  },
];
const loanStepsL = [
  {
    name: "Personal Information",
    className: "Personal-Information",
    num: 0,
  },
  {
    name: "Select Product",
    className: "Select-Product",
    num: 1,
  },
  {
    name: "Check your eligibility",
    className: "Check-your-eligibility",
    num: 2,
  },
  {
    name: "Choose & Apply",
    className: "Choose-and-Apply",
    num: 4,
  },
];
const stepsSM = [
  {
    name: "Step 1",
    className: "Personal-Information",
    num: 0,
  },
  {
    name: "Step 2",
    className: "Select-Product",
    num: 1,
  },
  {
    name: "Step 3",
    className: "Check-your-eligibility",
    num: 2,
  },
  {
    name: "Step 4",
    className: "Select-Card-Feature",
    num: 3,
  },
  {
    name: "Step 5",
    className: "Select-Card-Apply",
    num: 4,
  },
];
const loanStepsSM = [
  {
    name: "Step 1",
    className: "Personal-Information",
    num: 0,
  },
  {
    name: "Step 2",
    className: "Select-Product",
    num: 1,
  },
  {
    name: "Step 3",
    className: "Check-your-eligibility",
    num: 2,
  },
  {
    name: "Step 4",
    className: "Choose-and-Apply",
    num: 4,
  },
];
const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  const axios = useAxiosInstance();

  const flagRef = useRef();
  const countryRef = useRef();
  const cardRecommendationRef = useRef();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1440px)",
  });
  //  api data states
  const [countryCode, setCountryCode] = useState("");
  const [questions, setQuestions] = useState([]);
  const [features, setFeatures] = useState([]);

  const [subFeatureReviewFeaturesData, setsubFeatureReviewFeaturesData] =
    useState([]);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 431px)" });

  const [snackbar, setSnackbar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const { applicationId, customerId, setApplicationId, setCustomerId } = useContext(ApplicationIdContext);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [selectedSubFeaturesForHeader, setSelectedSubFeaturesForHeader] =
    useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [recommendationCount, setRecommendationCount] = useState(null);
  const [recommendationsMessage, setRecommendationsMessage] = useState("");
  const [cookieConsentGiven, setCookieConsentGiven] = useState(false);
  const [selectedSubFeatures, setSelectedSubFeatures] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [features, setFeatures] = useState(selectedSubFeatures);
  const [subFeatureReview, setsubFeatureReview] = useState(selectedSubFeatures);
  const [error, setError] = useState("");
  const [location, setLocation] = useState(null);
  const [screeningResponse, setScreeningResponse] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [loanRecommendations, setLoanRecommendations] = useState([]);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    promoCode: "",
    dialCode: "",
    errors: {},
  });

  const [selectedCard, setSelectedCard] = useState(null);
  const [customerData, setCustomerData] = useState(null); // State to store the fetched customer data
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [selectedFeatureIds, setSelectedFeatureIds] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [eligibilityData, setEligibilityData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    setCookieConsentGiven(consent === "given");
    fetchUserLocation();
  }, []);

  useEffect(() => {
    console.log(countryCode);
    countryRef.current?.setCountry(countryCode);
    localStorage.setItem("countryCode", countryCode);

    if (countryCode !== "ae") {
      setSelectedProduct("Card");
    }
    else {
      setSelectedProduct(null);
    }
  }, [countryCode]);


  const handleAcceptCookies = () => {
    setCookieConsentGiven(true);
    localStorage.setItem("cookieConsent", "given");
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleContactSupport = () => {
    window.location.href = "/help-and-support";
  };


  const fetchUserLocation = async () => {
    if (process.env.REACT_APP_REGION === "PK") {
      flagRef.current.setCountry("pk");
    }
    else {
      try {
        const response = await axios.get(`${serverBaseUrl}/api/geoIp2/public`);
        let countryCode = response.data.countryCode.toLowerCase();

        let countryCodes = ["ae", "sa"];
        if (countryCodes.includes(countryCode)) {
          flagRef.current.setCountry(countryCode);
        } else {
          flagRef.current.setCountry("ae");
        }
      } catch (error) {
        console.log(error);
        flagRef.current.setCountry("ae");
      }
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (formData.phone.length <= 4) {
      errors.phone = "Phone number is required";
      formIsValid = false;
    }

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
      formIsValid = false;
    }

    if (formData.email.length == 0) {
      errors.email = "Email is required";
      formIsValid = false;
    } else if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
      formIsValid = false;
    }

    setFormData((prev) => ({ ...prev, errors }));
    return formIsValid;
  };

  const validateEligibitForm = () => {
    let formIsValid = true;
    console.log("eligibilityData?????", eligibilityData);
    const allAnswered = questions?.every((question) =>
      eligibilityData.hasOwnProperty(question.questionId)
    );

    if (!allAnswered) {
      setError("Please answer all questions before proceeding.");
      formIsValid = false;
      setSnackbar({
        open: true,
        severity: "error",
        title: "Answers Required",
        message: "Please answer all questions before proceeding.",
      });

      // setOpenSnackbar(true);
    }
    return formIsValid;
  };

  //  handle Form Submit
  const handleFormSubmit = async (formData) => {
    console.log("Form data received in App.js:", formData);

    if (validateForm(formData)) {
      try {
        const customerData = {
          name: formData.fullName.trim(),
          email: formData.email,
          mobileNumber: `+${
            formData.dialCode.toString() + formData.phone.toString()
          }`,
          promoCode: formData.promoCode,
        };
        console.log("customerData received in App.js:", customerData);

        if (customerData.promoCode.length == 0) {
          customerData.promoCode = "1-1-other";
        }

        const customerResponse = await axios.post(
          "/api/customer-profiles/public",
          customerData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        setCustomerId(customerResponse.data.customerId);
        console.log(customerResponse);

        let locationCode = customerResponse.data.promoCode.split("-")[1];
        setLocation(locationCode);
        setSnackbar((prev) => ({ ...prev, open: false }));

        if (countryCode !== "ae") {
          startApplication(customerResponse.data.customerId, locationCode);
          fetchQuestions();
          setCurrentStep(2);
        }
        else {
          setCurrentStep(1);
        }
      } catch (error) {
        console.log(error.response.data);
        let errorTitle = error.response.data.title;
        setError(errorTitle);
        let errorMessage = error.response.data.detail;
        setSnackbar({
          open: true,
          title: errorTitle,
          message: errorMessage,
          severity: "error",
        });
      }
    }
  };

  const startApplication = async (cId, locationCode) => {
    const applicationData = {
      customer: { customerId: customerId | cId },
      location: {
        locationId: location | locationCode,
      },
      applicationType: selectedProduct,
      status: "Created",
    };

    const applicationResponse = await axios.post(
      "/api/applications/public",
      applicationData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    
    setApplicationId(applicationResponse.data.applicationId);
  };

  const validateProductSelection = () => {
    if (selectedProduct) {
      startApplication();
      fetchQuestions();
      return true;
    }
    let errorTitle = "Product Selection Required";
    setError(errorTitle);
    let errorMessage = "Before we proceed, please select a product";
    setSnackbar({
      open: true,
      title: errorTitle,
      message: errorMessage,
      severity: "error",
    });
    return false;
  };

  const fetchLoanOptions = async () => {
    try {
      const loanResponse = await axios.get(
        `/api/recommendations/public/loan/${applicationId}`
      );
      console.log("Loan data:", loanResponse.data);
      setLoanRecommendations(loanResponse.data);
      saveRecommendedProducts(loanResponse.data);
      return loanResponse.data;
    }
    catch (error) {
      console.error("Failed to fetch loan data:", error);
      return [];
    }
  };

  // handle Eligiblity Form Submit
  const handleEligiblityFormSubmit = async () => {
    if (!applicationId) {
      setError(
        "Application ID is not available. Please start from the beginning."
      );
      return;
    }

    const allAnswered = questions.every((question) =>
      eligibilityData.hasOwnProperty(question.questionId)
    );

    if (!allAnswered) {
      setError("Please answer all questions before proceeding.");
      return;
    }

    const payload = questions.map((question, index) => ({
      questionId: question.questionId,
      applicationId: applicationId,
      response: eligibilityData[question.questionId]?.optionId,
      screeningId:
        screeningResponse.length > 0
          ? screeningResponse[index].screeningId
          : null,
    }));

    let hardStop = Object.values(eligibilityData)
      .map((obj) => obj.hardstop)
      .includes(true);

    if (!hardStop) {
      try {
        const response = await axios.post("/api/screenings/public/bulk", payload, {
          headers: { "Content-Type": "application/json" },
        });
        console.log("Screening responses submitted:", response.data);

        if (response.data) {
          setScreeningResponse(response.data);
        }
      } catch (err) {
        console.error("There was an error submitting the responses:", err);
        setError("Failed to submit responses. Please try again later.");
      }
    }

    if (selectedProduct == "Loan") {
      if (!hardStop && (await fetchLoanOptions()).length !== 0) {
        setSnackbar((prev) => ({ ...prev, open: false }));
        setCurrentStep((prevStep) => prevStep + 2);
      }
      else {
        setModalOpen(true);
      }
      return;
    }

    if (!hardStop && (await calcCreditScore()) >= 60) {
      setModalOpen(false);
      fetchProductsFeatures();
      setSnackbar((prev) => ({ ...prev, open: false }));
      setSubStep(0);
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      setModalOpen(true);
    }
  };

  //handle Feature Accordion Submit
  const handleFeatureAccordionSubmit = () => {
    if (selectedCount < 3) {
      setSnackbar({
        open: true,
        title: "Feature Selection Required",
        message:
          "Before we proceed, please select at least three and up to five features.",
        severity: "warning",
      });
      return;
    } else if (selectedCount > 5) {
      setSnackbar({
        open: true,
        title: "Feature Selection Limit",
        message: "You can select maximum five features.",
        severity: "warning",
      });
      return;
    }

    // If no errors, proceed with the selected features
    const selectedFeaturesData = features?.flatMap((feature) =>
      feature.subFeatures.length > 0
        ? feature.subFeatures
            .filter((sf) => sf.selected)
            .map((sf) => ({
              featureId: sf.featureId,
              name: sf.featureName,
              image: sf.imageUrl,
            }))
        : feature.selected
        ? [
            {
              featureId: feature.featureId,
              name: feature.featureName,
              image: feature.imageUrl,
            },
          ]
        : []
    );
    // debugger;
    console.log(
      "Selected features data in FeaturesAccordion:",
      selectedFeaturesData
    );
    setSelectedSubFeatures(selectedFeaturesData);
    // return;
    if (subStep === 0 && selectedFeaturesData) {
      setSelectedSubFeatures(selectedFeaturesData);
      setSubStep(1);
    } else if (subStep === 1 && selectedFeaturesData) {
      setRecommendations(selectedFeaturesData);
      setSnackbar((prev) => ({ ...prev, open: false }));
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const calcCreditScore = async () => {
    try {
      const response = await axios.get(
        `/api/applications/public/credit-score/${applicationId}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
      setError("Failed to submit responses. Please try again later.");
    }
  };

  const saveRecommendedProducts = async (products) => {
    const payload = products.map((product) => ({
      applicationId: applicationId,
      productId: product.productId,
    }));

    try {
      const response = await axios.post(
        `${serverBaseUrl}/api/product-recommendations/public/${applicationId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Products Saved Data: " + response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const saveSelectedFeatures = async () => {
    const payload = selectedSubFeatures.map((subFeature) => ({
      applicationId: applicationId,
      featureId: subFeature.featureId,
    }));

    try {
      const response = await axios.post(
        `${serverBaseUrl}/api/selected-features/public/${applicationId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Features Saved Data: " + response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  // handle sub feature review Form Submit

  const handleSubFeatureReviewSubmit = async () => {
    console.log(selectedSubFeatures);
    // debugger;
    if (isSubmitting) {
      return;
    } else {
      setError(null);
      setIsSubmitting(true);
      const preferences = selectedSubFeatures.map((subFeature, index) => ({
        featureId: subFeature.featureId,
        priority: index + 1,
      }));

      const selectedFeatureIds = selectedSubFeatures.map(
        (subFeature) => subFeature.featureId
      );

      const payload = {
        applicationId: applicationId,
        preferences: preferences,
      };

      try {
        const response = await axios.post(
          `${serverBaseUrl}/api/recommendations/public`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        try {
          const cardsResponse = await axios.get(
            `${serverBaseUrl}/api/recommendations/public/${applicationId}/filter`,
            {
              params: {
                page: 0,
                size: 10,
                preferences: selectedFeatureIds + "",
              },
            }
          );
          console.log("Cards data:", cardsResponse.data);

          let data = {
            selectedFeatureIds,
            recommendations: cardsResponse.data.content,
          };
          setRecommendations(cardsResponse.data.content);
          setSelectedFeatureIds(selectedFeatureIds);
          saveSelectedFeatures();
          saveRecommendedProducts(response.data);
          // onNext(data);
        } catch (error) {
          console.error("Failed to fetch application data:", error);
        }
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        handleSubFeatureReviewErrorResponse(error);
      } finally {
        setIsSubmitting(false); // Re-enable the button
      }
    }
  };

  const handleSubFeatureReviewErrorResponse = (error) => {
    if (error.response) {
      console.error(
        `Server responded with status ${error.response.status}`,
        error.response.data
      );
      setError(`Server error: ${error.response.status}`);
    } else if (error.request) {
      console.error(
        "The request was made but no response was received",
        error.request
      );
      setError("No response from server");
    } else {
      console.error("Error setting up the request", error.message);
      setError("Request setup error");
    }
  };

  // handle submit application
  const handleSubmitApplication = async () => {
    // Check if a card is selected

    if (!selectedCard) {
      setSnackbar({
        open: true,
        title: "Card Selection",
        message: "Please select a card first to apply.",
        severity: "error",
      });
      return;
    }

    // Show a loading indicator or something similar if needed
    // setModalOpen(true);
    // setTimeout(() => {
    //   window.open(selectedCard.routingUrl, "_blank");
    //   setModalOpen(false);
    // }, 2000);

    // Construct the payload
    const payload = {
      applicationId: applicationId,
      customer: {
        customerId: customerData?.customerId,
      },
      location: {
        locationId: location,
      },
      product: {
        productId: selectedCard.productId,
      },
      productType: "Card",
      clientRiskProfile: customerData?.clientRiskProfile,
      status: "Submitted",
    };

    try {
      // Make the API call
      const response = await axios.post("/api/applications/public", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        // After a successful API call, redirect the user
        // window.location.href = "/congratulations";
      }
      // Handle the response
      console.log("Application submitted:", response.data);

      // If the API call is successful, and you have a routing URL, redirect the user
      if (response.data?.product?.routingUrl) {
        // window.open(response.data.product.routingUrl, "_blank");
        // setModalOpen(true);
        cardRecommendationRef.current.openModal();
        setTimeout(() => {
          window.open(selectedCard.routingUrl, "_blank");
          cardRecommendationRef.current.closeModal();
          window.location.href = "/congratulations";
        }, 2000);
      }
      await SendEmail(
        customerData,
        applicationId,
        selectedSubFeatures,
        selectedCard.productName
      );
    } catch (error) {
      console.error("Error submitting application:", error);
      // Handle the error, show a message to the user if needed
    } finally {
      // Hide the loading indicator
      setModalOpen(false);
    }
  };

  const selectedFeatureNames = selectedSubFeatures
    .map((feature) => feature.name)
    .join(", ");
  // console.log("Selected Credit Card Features:", selectedFeatureNames);

  // Inside App.js
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false, title: "", message: "" });
  };

  const goToNextStep = (data) => {

    if (!isExecuting) {
      setIsExecuting(true);
      // console.log("Data received in goToNextStep:", validateEligibitForm());

      // return;
      if (currentStep === 0 && validateForm(formData)) {
        // Validate before moving on
        handleFormSubmit(formData); // Assuming there's an event object
        // setCurrentStep((prevStep) => prevStep + 1);
      } else if (currentStep === 1 && validateProductSelection()) {
        // Validate before moving on
        setCurrentStep((prevStep) => prevStep + 1);
      } else if (currentStep === 2 && validateEligibitForm()) {
        // Validate before moving on
        handleEligiblityFormSubmit(); // Assuming there's an event object
        // setCurrentStep((prevStep) => prevStep + 1);
      } else if (currentStep === 3) {
        if (subStep === 0) {
          handleFeatureAccordionSubmit();

          // setSubStep(1);
        } else if (subStep === 1) {
          handleSubFeatureReviewSubmit();

          setCurrentStep(currentStep + 1);
        }
      }
      // } else {
      //   setCurrentStep((prevStep) => prevStep + 1);
      // }
      setTimeout(() => {
        setIsExecuting(false);
      }, 3000); 
    }
  };
  const goToPreviousStep = () => {
    
    if (currentStep === 3 && subStep === 1 && selectedProduct === "Card") {
      setSubStep(0);
      setSelectedSubFeatures((previousFeatures) => {
        return [...previousFeatures]; // This needs to be replaced with actual logic
      });
    }
    else if (currentStep === 2 && countryCode !== "ae") {
      setCurrentStep((prevStep) => prevStep - 2);
    } 
    else if (currentStep === 4 && selectedProduct === "Loan") {
      setCurrentStep(1);
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const resetFilter = () => {
    if (currentStep === 4 && selectedProduct == "Card") {
      setSubStep(0);
      setCurrentStep((prevStep) => prevStep - 1);
    }
    else {
      setSubStep(0);
      setCurrentStep((prevStep) => prevStep - 3);
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`/api/questions/public?productType=${selectedProduct}`, {
        headers: { "Content-Type": "application/json" },
      });
      setQuestions(response.data);
    } catch (err) {
      console.error("There was an error fetching the questions:", err);
      setError("Failed to fetch questions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // productFeatures
  const fetchProductsFeatures = async () => {
    axios
        .get("/api/productFeatures/public")
        .then((response) => {
          const featuresWithSelection = response.data.map((feature) => ({
            ...feature,
            subFeatures: feature.subFeatures
              ? feature.subFeatures.map((subFeature) => ({
                  ...subFeature,
                  selected: selectedSubFeatures.some(
                    (sf) => sf.featureId === subFeature.featureId
                  ),
                }))
              : [],
            selected: selectedSubFeatures.some(
              (sf) => sf.featureId === feature.featureId
            ),
          }));

          setFeatures(featuresWithSelection);
        })
        .catch((error) => {
          console.error("Error fetching features:", error);
          setSnackbar({
            open: true,
            message: "Failed to load features.",
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
  };


  const backToHome = () => {
    setCurrentStep(0);
  };

  return (
    <Router>
      <div className="App">
        <Snackbar
          className="snackbar"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            className="snackbar-alert"
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity="error"
            sx={{ width: "100%" }}
          >
            <AlertTitle className="snackbar-alert-title">
              {snackbar.title}
            </AlertTitle>
            <span>{snackbar.message}</span>
          </Alert>
        </Snackbar>

        <Header
          backToHome={backToHome}
          ref={flagRef}
          setCountryCode={setCountryCode}
        />
        {!cookieConsentGiven ? (
          <CookieConsentPopup onAccept={handleAcceptCookies} />
        ) : (
          <Routes>
            <Route path="/help-and-support" element={<HelpAndSupport />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/congratulations" element={<CongratulationsPage selectedProduct={selectedProduct} />} />

            <Route
              path="/"
              element={
                <>
                  <div className="stepIndicator-component-container">
                    <div className="step-indicator-parent-container">
                      <StepIndicator
                        steps={
                          selectedProduct === "Card" ? isMobile ? stepsSM
                          : stepsL
                          : isMobile ? loanStepsSM : loanStepsL
                        }
                        currentStep={currentStep}
                      />
                    </div>
                    {/* <div className="component-container"> */}
                    {currentStep === 0 && (
                      <Form
                        ref={countryRef}
                        countryCode={countryCode}
                        formData={formData}
                        setFormData={setFormData}
                        onNext={goToNextStep}
                        onApplicationIdCreated={setApplicationId}
                        onPrevious={goToPreviousStep}
                      />
                    )}
                    {currentStep === 1 && (
                      <SelectProduct
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                      />
                    )}
                    {currentStep === 2 && (
                      <EligibilityForm
                        data={eligibilityData}
                        setData={setEligibilityData}
                        onNext={goToNextStep}
                        questions={questions}
                        loading={loading}
                        onPrevious={goToPreviousStep}
                      />
                    )}
                    {currentStep === 3 && subStep === 0 && (
                      <FeaturesAccordion
                        loading={loading}
                        features={features}
                        snackbar={snackbar}
                        selectedCount={selectedCount}
                        setSelectedCount={setSelectedCount}
                        setSnackbar={setSnackbar}
                        setFeatures={setFeatures}
                        selectedSubFeatures={selectedSubFeatures}
                        setSelectedSubFeatures={setSelectedSubFeatures}
                        selectedSubFeaturesForHeader={
                          selectedSubFeaturesForHeader
                        }
                        setSelectedSubFeaturesForHeader={
                          setSelectedSubFeaturesForHeader
                        }
                        onNext={goToNextStep}
                        onPrevious={goToPreviousStep}
                        priorityList={priorityList}
                        setPriorityList={setPriorityList}
                        recommendationCount={recommendationCount}
                        setRecommendationCount={setRecommendationCount}
                        recommendationsMessage={recommendationsMessage}
                        setRecommendationsMessage={setRecommendationsMessage}
                        setLoading={setLoading}
                      />
                    )}
                    {currentStep === 3 && subStep === 1 && (
                      <SubFeatureReview
                        // selectedFeatureIds={selectedFeatureIds}
                        // selectedSubFeatures={
                        //   subFeatureReviewFeaturesData?.length !== 0
                        //     ? subFeatureReviewFeaturesData
                        //     : selectedSubFeatures
                        // }
                        selectedSubFeatures={selectedSubFeatures}
                        setSelectedSubFeatures={setSelectedSubFeatures}
                        selectedCount={selectedCount}
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        // subFeatureReviewFeaturesData={
                        //   subFeatureReviewFeaturesData
                        // }
                        setsubFeatureReviewFeaturesData={
                          setsubFeatureReviewFeaturesData
                        }
                        // Pass the setter function // Pass the setter function
                      />
                    )}
                    {currentStep === 4 && (
                      selectedProduct == "Card" ?
                      <CardRecommendations
                        ref={cardRecommendationRef}
                        countryCode={countryCode}
                        cardData={recommendations}
                        onPrevious={resetFilter}
                        selectedSubFeatures={selectedSubFeatures}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        customerData={customerData}
                        setCustomerData={setCustomerData}
                        selectedFeatureIds={selectedFeatureIds}
                        setSnackbar={setSnackbar}
                        // data states
                        setFormData={setFormData}
                        setEligibilityData={setEligibilityData}
                        setSelectedSubFeatures={setSelectedSubFeatures}
                        setSelectedSubFeaturesForHeader={
                          setSelectedSubFeaturesForHeader
                        }
                        setsubFeatureReviewFeaturesData={
                          setsubFeatureReviewFeaturesData
                        }
                      />
                    :
                      <LoanRecommendations
                        ref={cardRecommendationRef}
                        countryCode={countryCode}
                        loanData={loanRecommendations}
                        onPrevious={resetFilter}
                        selectedSubFeatures={selectedSubFeatures}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        customerData={customerData}
                        setCustomerData={setCustomerData}
                        selectedFeatureIds={selectedFeatureIds}
                        setSnackbar={setSnackbar}
                        // data states
                        setFormData={setFormData}
                        setEligibilityData={setEligibilityData}
                        setSelectedSubFeatures={setSelectedSubFeatures}
                        setSelectedSubFeaturesForHeader={
                          setSelectedSubFeaturesForHeader
                        }
                        setsubFeatureReviewFeaturesData={
                          setsubFeatureReviewFeaturesData
                        }
                      />
                    )}
                    {/* </div> */}

                    <div className="main-bottom-button-container">
                      {currentStep !== 0 && currentStep !== 4 && (
                        <button onClick={goToPreviousStep} className="back-btn">
                          Back
                        </button>
                      )}
                      {currentStep == 4 && isMobile && (
                        <button onClick={goToPreviousStep} className="back-btn">
                          Back
                        </button>
                      )}

                      {currentStep !== 4 ? (
                        <button
                          type="submit"
                          onClick={goToNextStep}
                          className="submit-btn"
                        >
                          Next
                        </button>
                      ) : ""}
                    </div>
                  </div>

                  <Modal 
                    open={modalOpen}
                    icon={warningIcon}
                    title={"Unfortunately, we don’t have any products that fits your profile"} 
                    body={"Seems like you are not eligible for any product at the moment. We have your details and we will get in touch with you when something fits your profile."}
                    primaryBtnText={"Ok"}
                    handlePrimaryBtn={handleCloseModal}
                  />
                  <ErrorModal 
                    open={errorModalOpen}
                    icon={warningIcon}
                    title={"Oops! Something went wrong"} 
                    body={"We're experiencing an unexpected issue on our end. Please try refreshing the page, or come back later."}
                    primaryBtnText={"Refresh page"}
                    handlePrimaryBtn={handleRefreshPage}
                    secondaryBtnText={"Contact support"}
                    handleSecondaryBtn={handleContactSupport}
                  />
                </>
              }
            />
          </Routes>
        )}
        {/* <Footer /> */}
      </div>
    </Router>
  );
};

export default App;
