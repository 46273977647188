import React, { useState, useEffect, useContext } from "react";
import "./EligibilityForm.css";
import axios from "../../api/axios";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
// import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import AlertTitle from "@mui/material/AlertTitle";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });
function EligibilityForm({
  data,
  setData,
  loading,
  questions,
  onNext,
  onPrevious,
  screeningResponseData,
}) {
  // const [data, setData] = useState(true);
  // const [questions, setQuestions] = useState([]);

  // const [error, setError] = useState("");
  // const [screeningResponse, setScreeningResponse] = useState(null);
  const { applicationId } = useContext(ApplicationIdContext);
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [openEligibilityAlert, setOpenEligibilityAlert] = useState(false);

  // useEffect(() => {
  //   console.log(screeningResponseData);
  //   if (screeningResponse) {
  //     calcCreditScore();
  //   }
  // }, [screeningResponse]);

  // useEffect(() => {
  //   if (error) {
  //     setOpenSnackbar(true);
  //   }
  // }, [error]);

  // useEffect(() => {
  //   async function fetchQuestions() {
  //     try {
  //       const response = await axios.get("/api/questions", {
  //         headers: { "Content-Type": "application/json" },
  //       });
  //       setQuestions(response.data);
  //     } catch (err) {
  //       console.error("There was an error fetching the questions:", err);
  //       setError("Failed to fetch questions. Please try again later.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   if (applicationId) {
  //     fetchQuestions();
  //   }
  // }, [applicationId]);

  // const handleCloseSnackbar = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenSnackbar(false);
  // };

  // const handleEligibilityAlert = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenEligibilityAlert(false);
  // };

  // const calcCreditScore = async () => {
  //   try {
  //     const response = await axios.get(
  //       `/api/applications/credit-score/${applicationId}`
  //     );
  //     console.log(response.data);
  //     if (response.data >= 60) {
  //       onNext(screeningResponse);
  //     } else {
  //       setOpenEligibilityAlert(true);
  //     }
  //   } catch (err) {
  //     setError("Failed to submit responses. Please try again later.");
  //   }
  // };

  const handleOptionChange = (questionId, optionId, hardStop) => {
    console.log("questionId>>>>", { questionId, optionId, hardStop });
    const newSelectedOptions = { ...data, [questionId]: {"optionId": optionId, "hardstop": hardStop}};
    console.log("newSelectedOptions>>>>", newSelectedOptions);
    setData(newSelectedOptions); // Update state in the parent component
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!applicationId) {
  //     setError(
  //       "Application ID is not available. Please start from the beginning."
  //     );
  //     return;
  //   }

  //   const allAnswered = questions.every((question) =>
  //     data.hasOwnProperty(question.questionId)
  //   );

  //   if (!allAnswered) {
  //     setError("Please answer all questions before proceeding.");
  //     return;
  //   }

  //   const payload = questions.map((question) => ({
  //     questionId: question.questionId,
  //     applicationId: applicationId,
  //     response: data[question.questionId],
  //   }));

  //   try {
  //     const response = await axios.post("/api/screenings/bulk", payload, {
  //       headers: { "Content-Type": "application/json" },
  //     });
  //     console.log("Screening responses submitted:", response.data);
  //   } catch (err) {
  //     console.error("There was an error submitting the responses:", err);
  //     setError("Failed to submit responses. Please try again later.");
  //   }

  //   try {
  //     const response = await axios.get(
  //       `/api/applications/credit-score/${applicationId}`
  //     );
  //     console.log(response.data);
  //     if (response.data >= 60) {
  //       onNext();
  //     } else {
  //       setError(
  //         "You are not eligible to go to the next step based on your answers."
  //       );
  //       setOpenSnackbar(true);
  //     }
  //   } catch (err) {
  //     setError("Failed to submit responses. Please try again later.");
  //   }
  // };

  if (loading) return <div>Loading questions...</div>;
  if (!applicationId)
    return (
      <div>
        Application ID is not available. Please start from the beginning.
      </div>
    );

  return (
    <div className="feature-selection-container">
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar> */}

      {/* <Snackbar
        open={openEligibilityAlert}
        onClose={handleEligibilityAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          className="eligibility-alert"
          onClose={handleEligibilityAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          <AlertTitle className="eligibility-alert-title">
            Unfortunately, we don’t have any products that fits your profile.
          </AlertTitle>
          <span>
            Seems like your are not eligible for any product at the moment. We
            have your details and we will get in touch with you when something
            fits your profile.
          </span>
        </Alert>
      </Snackbar> */}

      <div className="selection-section ml-4">
        <h1 className="form-title">
          A few
          <span className="know"> questions</span> to get you started
        </h1>
        <span className="form-title-underline"></span>

        <div style={{ marginTop: "8px" }}>
          {questions?.map((question) => (
            <div key={question.questionId} className="question-item">
              <p className="question-text">{question.question}</p>
              <div className="options-container">
                {question.options.map((option) => (
                  <label key={option.optionId} className="option">
                    <input
                      type="radio"
                      name={`question-${question.questionId}`}
                      value={option.optionId}
                      checked={data[question.questionId]?.optionId === option.optionId}
                      onChange={() =>
                        handleOptionChange(question.questionId, option.optionId, option.hardStop)
                      }
                    />
                    <span className="custom-radio"></span>
                    <span className="label-text">{option.optionText}</span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* <div className="eligibilty-selection-actions">
          <button className="back-btn" onClick={onPrevious}>
            Back
          </button>
          <button
            className="eligibilty-selection-next-btn"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default EligibilityForm;
