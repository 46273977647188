import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa"; // Ensure you have `react-icons` installed
import line from "../../images/yellow line.png";
import helpSupportContainerImg from "../../images/help_support_container_img.png";
import "./HelpAndSupport.css";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import success from "../../images/ic24-success.png";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "@mui/material";
import useAxiosInstance from "../../api/axios";
import PrivacyPolicyBottomSheet from "../PrivacyPolicyBottomSheet/PrivacyPolicyBottomSheet";

function Modal({ onClose }) {
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close-btn" onClick={onClose}>
          ×
        </button>
        <div className="modal-header"></div>
        <img className="success" src={success} alt="Logo"></img>
        <div className="modal-body">
          <p>
            Thank you for reaching out! Your inquiry has been successfully
            submitted. Our team aims to respond to inquiries within 2-3 business
            days.{" "}
          </p>
          <p>Thank you for your patience! :)</p>
        </div>
      </div>
    </div>
  );
}

function SupportForm() {
  const axios = useAxiosInstance();

  const [privacyPolicyModal, setprivacyPolicyModal] = useState(false);
  const [showModal, setShowModal] = useState(false); // Correct placement
  const isMobile = useMediaQuery({ query: "(max-width: 426px)" });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
    agreeToTerms: false,
  });

  const [focus, setFocus] = useState({
    fullName: false,
    email: false,
    message: false,
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    message: "",
    agreeToTerms: false,
  });

  const handleChange = (event) => {
    console.log("Checkbox changed", event.target.checked);
    const { name, type, checked, value } = event.target;
    if (name === "fullName") {
      const newFullName = value.replace(/[^a-zA-Z ]/g, "");

      setFormData((prevState) => ({
        ...prevState,
        [name]: newFullName,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleFocus = (name) => {
    setFocus((prev) => ({ ...prev, [name]: true }));
  };

  const handleBlur = (name) => {
    setFocus((prev) => ({ ...prev, [name]: formData[name] ? true : false }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formValid = true;
    const newErrors = { ...errors };

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Please enter your full name";
      formValid = false;
    } else {
      newErrors.fullName = "";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email";
      formValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email";
      formValid = false;
    } else {
      newErrors.email = "";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Please enter your message";
      formValid = false;
    } else {
      newErrors.message = "";
    }

    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = "Please agree to the terms";
      formValid = false;
    } else {
      newErrors.agreeToTerms = "";
    }

    setErrors(newErrors);

    if (formValid) {
      console.log("Form data submitted:", formData);

      const emailPayload = {
        to: formData.email,
        // tO: null,
        containImages: true,

        subject: "Help & Support Request Received",
        body: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${process.env.REACT_APP_TITLE} - Support Email Response</title>
    <link
      rel="stylesheet"
      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
      crossorigin="anonymous"
    />

    <style>
      /* Global styles */
      body {
        font-family: sans-serif;
        margin: 0;
        padding: 0;
      }

      .container {
        max-width: 700px;
        margin: 0 auto;
        padding: 20px;
      }

      .header {
        text-align: center;
      }

      .message {
        line-height: 1.5;
      }

      .information {
        margin-top: 20px;
        padding: 10px 15px;
        border-radius: 5px;
      }

      .call-to-action {
        text-align: center;
        margin-top: 20px;
      }

      .unsubscribe {
        text-align: center;
        font-size: 0.8em;
        margin-top: 20px;
      }

      .copyright {
        text-align: center;
        font-size: 0.8em;
        margin-top: 10px;
      }

      .footer {
        background: #faf6f9;
        padding: 20px;
        text-align: center;
        color: #2f1329;
      }

      .social-icons-containers {
        margin: 20px 0;
        text-align: center;
      }

      .social-icons-containers a {
        margin: 0 10px;
        color: #711c60;
        text-decoration: none;
        font-size: 24px;
      }

      .social-icons-containers a:hover {
        color: #711c60;
        font-size: 24px;
      }

      /* Media query for smaller screens */
      @media only screen and (max-width: 768px) {
        .container {
          padding: 10px;
        }

        .information {
          font-size: 0.9em;
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <img src="cid:logo01" style="width: 500px" alt="${process.env.REACT_APP_TITLE}" />
      </div>
      <div class="message">
        <p>Hi <strong>${formData.fullName}!</strong></p>
        <p>Thank you for reaching out to us!</p>
        <p>
          Your message has been received, and we're on it. Our team is dedicated
          to providing you with the best support possible.
        </p>
        <p>Here's a quick recap of the information you provided:</p>
        <div class="information">
          <p>Name: <strong>${formData.fullName}</strong></p>
          <p>Email: <strong>${formData.email}</strong></p>
          <p>Message: <strong>${formData.message}</strong></p>
        </div>
        <p>
          We'll review your message and get back to you as soon as we can. In
          the meantime, if you have any urgent concerns, feel free to reach out
          to us directly at
          <a href="mailto:support@${process.env.REACT_APP_DOMAIN}">
            <strong>support@${process.env.REACT_APP_DOMAIN}</strong>
          </a>
        </p>
        <p>Thank you for choosing us! We appreciate your patience.</p>
        <p>Best Regards,</p>
        <p>${process.env.REACT_APP_TITLE}</p>
      </div>
      <h2
        style="color: #711c60 !important; text-align: center; margin-top: 3rem"
      >
        Visit the ${process.env.REACT_APP_TITLE} website!
      </h2>
      <p
        style="
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
        "
      ></p>
      <p
        style="
          text-align: center;
          font-style: normal;
          color: #2f1329;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
        "
      >
        Get the most of ${process.env.REACT_APP_TITLE} by visiting the website. You can log
        in by using your existing email address and password.
      </p>
      <a
        href="https://www.${process.env.REACT_APP_DOMAIN}"
        class="cta"
        style="
          display: block;
          text-align: center;
          color: white;
          text-decoration: none;
          margin: 20px auto;
          box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16),
            0px 4px 8px -2px #030c28;
          border-radius: 4px;
          background: #711c60;
          padding: 16px 32px;
          font-weight: 500;
          width: 200px;
        "
        >Visit Website</a
      >
    </div>
    <div class="footer">
      <div class="social-icons-containers">
        <a href="https://twitter.com" target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <a href="https://www.facebook.com" target="_blank"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a href="https://www.linkedin.com" target="_blank"
          ><i class="fab fa-linkedin-in"></i
        ></a>
      </div>
      <p>
        We hate to see you go, but if you must
        <a href="#" style="color: #5b7194">unsubscribe</a>
      </p>
      <p>
        Copyright &copy; ${new Date().getFullYear()} ${process.env.REACT_APP_TITLE}. All
        rights reserved
      </p>
    </div>
  </body>
</html>
 `,
      };

      try {
        const emailResponse = await axios.post("/api/email/public", emailPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Email sent successfully:", emailResponse.data);
        // debugger;
        if (emailResponse.status === 200) {
          setShowModal(true);
          setFormData({
            fullName: "",
            email: "",
            message: "",
            agreeToTerms: false,
          });
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="help-and-support-container">
        <span className="help-support-page-title-2">
          <h1 className="form-title">
            Help and <span className="know">support</span>
          </h1>
          <p className="para">
            Briefly summarize your inquiry or issue in the message box.
          </p>
        </span>
        <div className="section-container">

        <div className="support-image-container">
            <img src={helpSupportContainerImg} alt="help_support_container_img" />
          </div>

          <div className="help-and-support-section-container ">
            <div className="help-and-support-section ml-4">
              <span className="help-support-page-title-1">
                <h1 className="form-title">
                  Help and <span className="know">support</span>
                </h1>
                <p className="para">
                  Briefly summarize your inquiry or issue in the message box.
                </p>
              </span>
              <FormGroup onSubmit={handleSubmit}>
                <TextField
                  error={errors.fullName ? true : false}
                  id="fullName"
                  size={isMobile ? "small" : "normal"}
                  fullWidth
                  label="Full Name"
                  name="fullName"
                  variant="outlined"
                  margin="normal"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="custom-textField input"
                  helperText={errors.fullName}
                />

                <TextField
                  error={errors.email ? true : false}
                  id="email"
                  size={isMobile ? "small" : "normal"}
                  fullWidth
                  label="Email"
                  name="email"
                  variant="outlined"
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                  className="custom-textField input"
                  helperText={errors.email}
                />

                <TextField
                  error={errors.message ? true : false}
                  id="message"
                  size={isMobile ? "small" : "normal"}
                  fullWidth
                  label="Message*"
                  name="message"
                  variant="outlined"
                  margin="normal"
                  value={formData.message}
                  onChange={handleChange}
                  className="custom-textField input"
                  helperText={errors.message}
                  multiline
                  rows={4}
                />

                <div className="check-box-field">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          require={true}
                          checked={formData?.agreeToTerms}
                          defaultChecked
                          name="agreeToTerms"
                          onClick={handleChange}
                          // value={formData.agreeToTerms}
                        />
                      }
                      label={
                        <label
                          className="checkbox-text"
                          htmlFor="testCheckbox"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              agreeToTerms: !formData?.agreeToTerms,
                            });
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <Link
                            to="#"
                            onClick={() => setprivacyPolicyModal(true)}
                          >
                            privacy policy{" "}
                          </Link>{" "}
                          and{" "}
                          <Link to="/terms-of-service">terms of service.</Link>
                        </label>
                      }
                    />
                  </div>
                  <span className="helpandsupport-error-message ">
                    {errors.agreeToTerms}
                  </span>
                </div>
              </FormGroup>
            </div>
          </div>
        </div>

        {showModal && <Modal onClose={closeModal} />}
        {privacyPolicyModal && (
          <PrivacyPolicyBottomSheet
            open={privacyPolicyModal}
            setOpen={setprivacyPolicyModal}
            onClose={() => {
              // console.log("true");
            }}
            onAgree={() => {
              // console.log("true");
            }}
          />
        )}
        <div className="helpandsupport-selection-actions">
          <button
            onClick={() => (window.location.href = "/")}
            className="helpandsupport-selection-back-to-home"
          >
            Back to Home
          </button>
          <button
            className="helpandsupport-selection-submit-btn"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default SupportForm;
