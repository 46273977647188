import React, {
  forwardRef,
  useImperativeHandle,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import { ThreeDots } from "react-loader-spinner";
import logo from "../../images/logo.svg";
import bestOfferBazaarLogo from "../../images/best-offer-bazaar-logo.svg";
import uaeFlag from "../../images/uae1.png";
import saudiFlag from "../../images/sud1.png";
import pakistanFlag from "../../images/pak1.png";
import dropDown from "../../images/close.png";
import line from "../../images/Line 18.png";
import { ApplicationIdContext } from "../../contexts/ApplicationIdContext";
import ToggleSwitchComponent from "../ToggleSwitchComponent/ToggleSwitchComponent";
import { useMediaQuery } from "react-responsive";

const allowedCountries = [
  {
    flagUrl: uaeFlag,
    country: {
      code: "ae",
      alpha2Code: "AE",
      shortCode: "UAE",
    },
  },
  {
    flagUrl: saudiFlag,
    country: {
      code: "sa",
      alpha2Code: "SA",
      shortCode: "KSA",
    },
  },
  // {
  //   flagUrl: pakistanFlag,
  //   country: {
  //     code: "pk",
  //     alpha2Code: "PK",
  //     shortCode: "PAK",
  //   },
  // },
];

const Header = forwardRef(({ backToHome, setCountryCode }, ref) => {
  const location = useLocation();
  const [selectedFlag, setSelectedFlag] = useState(null);
  const [isFlagOpen, setIsFlagOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(false);
  const [isEnglish, setisEnglish] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // const [logo, setLogo] = useState(null);
  // useEffect(() => {
  //   if (process.env.REACT_APP_REGION === "PK") {
  //     import('../../images/best-offer-bazaar-logo.svg').then(data => {
  //     setLogo(data.default);
  //   });
  // } else {
  //     import('../../images/logo.svg').then(data => {
  //     setLogo(data.default);
  //   });
  // }
  // }, []);

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    setCountry(countryCode) {
      if (countryCode === "sa") {
        setSelectedFlag(allowedCountries[1]);
      } else {
        setSelectedFlag(allowedCountries[0]);
      }
      setCountryCode(countryCode);
      setselectedCountry(countryCode);
    },
  }));

  const { selectedCountry, setselectedCountry } =
    useContext(ApplicationIdContext);

  const handleOpenFlag = () => {
    let elem = document.getElementsByClassName("dropdown-arrow1")[0];
    if (isFlagOpen) {
      setIsFlagOpen(false);
      elem.classList.remove("opened");
    }
    else {
      setIsFlagOpen(true);
      elem.classList.add("opened");
    }
    
    
    
  }

  const handleFlagChange = (flag) => {
    let elem = document.getElementsByClassName("dropdown-arrow1")[0];
    elem.classList.remove("opened");
    setSelectedFlag(flag);
    localStorage.setItem("selectedCountry", flag.country.code);
    setCountryCode(flag.country.code);
    setselectedCountry(flag.country.code);
    setIsFlagOpen(false);
    backToHome();
  };

  const handleLogoClick = () => {
    navigate("/");
    window.location.reload();
  }


  return (
    <>
      <header>
        <Link to="/">
          <img
            className="logo"
            src={process.env.REACT_APP_REGION === "PK" ? bestOfferBazaarLogo : logo}
            alt={process.env.REACT_APP_TITLE}
            onClick={handleLogoClick}
          />
        </Link>
        <div className="right-sideee">
          {location.pathname !== "/help-and-support" && (
            <Link to="/help-and-support" className="btn-support">
              Help & Support
            </Link>
          )}

          {process.env.REACT_APP_REGION !== "PK" ? (
          <>
            <span className="line">
              <img src={line} alt="line" />
            </span>
            
            {selectedFlag == null ? (
              <ThreeDots
                visible={true}
                height="50"
                width="30"
                color="#711c60"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              <div className="flag-dropdown1">
                <div className="count-chip-dropdown" onClick={handleOpenFlag}>
                  <img
                    src={selectedFlag.flagUrl}
                    alt="Selected Country Flag"
                    className="selected-flag"
                  />
                  <span className="country-alpha-code" style={{ margin: "0px 5px" }}>
                    {isMobile ? selectedFlag?.country?.alpha2Code : selectedFlag?.country?.shortCode}
                  </span>
                  <span className="dropdown-arrow1">
                    <img src={dropDown} alt="dropdown" />
                  </span>
                </div>
                <div className="dropdown-content1" style={{display: isFlagOpen ? "flex" : "none"}}>
                  {allowedCountries?.map((allCoun, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                      }}
                      onClick={() => handleFlagChange(allCoun)}
                    >
                      <img
                        key={allCoun.country.code}
                        src={allCoun.flagUrl}
                        alt={allCoun?.country?.code}
                      />
                      <span  style={{ margin: "6px 0px" }}>
                        {isMobile ? allCoun?.country?.alpha2Code : allCoun?.country?.shortCode}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
          ) : ''}
          </div>
      </header>
    </>
  );
});

export default Header;
