import React from "react";
import "./Modal.css";

const Modal = ({ open, icon, title, body, secondaryBtnText, handleSecondaryBtn, primaryBtnText, handlePrimaryBtn }) => {
    return (
      <>
        {open && (
          <div className="modal-overlay">
            <div className="modal-box">
              <div className="modal-box-inner">
                <div><img src={icon} /></div>
                <div className="modal-text-content">
                  <h2 className="modal-title">{title}</h2>
                  <p className="modal-text">{body}</p>
                </div>
                <div className="action-buttons">
                  { secondaryBtnText && <button className="select-button back-button" onClick={handleSecondaryBtn}>{secondaryBtnText}</button> }
                  <button className="select-button" onClick={handlePrimaryBtn}>{primaryBtnText}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  export default Modal;